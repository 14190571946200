import ModalTitle, {
  type ModalTitleProps,
} from '@components/typography/ModalTitle'
import { openModal } from '@mantine/modals'
import type { ModalSettings } from '@mantine/modals/lib/context'

export type OpenBaseModalProps = Omit<ModalSettings, 'title'> & {
  title: ModalTitleProps | null
}

const openBaseModal = (props: OpenBaseModalProps) =>
  openModal({
    padding: 24,
    styles: {
      body: {
        paddingTop: 20,
      },
      header: {
        padding: 0,
        zIndex: 10,
      },
      title: {
        width: '100%',
      },
    },
    ...props,
    title: props.title ? <ModalTitle {...props.title} /> : null,
    withCloseButton: false,
  })

export default openBaseModal
