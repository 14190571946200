import RingBgMd from '@assets/backgrounds/ring-pattern-md.svg'
import SquareBgMd from '@assets/backgrounds/square-pattern-md.svg'
import IllustrationBox from '@assets/illustrations/box-sm.svg'
import IllustrationCloud from '@assets/illustrations/cloud-sm.svg'
import IllustrationDocuments from '@assets/illustrations/documents-sm.svg'
import type { SVGIcon } from '@icons'
import type { TablerIcon } from '@types'
import { cn } from '@utils'
import { type SVGProps, useMemo } from 'react'

type IconRingWrapperProps = {
  readonly icon: SVGIcon | TablerIcon
  readonly iconProps?: SVGProps<SVGSVGElement>
  readonly padding?: `${number}px` | `${number}rem`
  readonly ringBgClassName?: string
  readonly size: `${number}px` | `${number}rem`
  readonly variant?: 'default' | 'danger' | 'warning'
  readonly pattern?: 'rings' | 'squares'
  readonly illustration?: 'none' | 'cloud' | 'box' | 'documents'
}

const Illustration = ({
  illustration,
}: Pick<IconRingWrapperProps, 'illustration'>) => {
  switch (illustration) {
    case 'cloud':
      return <IllustrationCloud />

    case 'box':
      return <IllustrationBox />

    case 'documents':
      return <IllustrationDocuments />
    default:
      return null
  }
}

const IconRingWrapper = ({
  icon,
  size,
  ringBgClassName,
  iconProps,
  padding,
  variant,
  pattern = 'rings',
  illustration = 'none',
}: IconRingWrapperProps) => {
  const Icon = icon

  const iconColors = {
    danger: 'text-red-500',
    default: 'text-gray-700',
    warning: 'text-warning-500',
  }

  const IllustrationGraphic = useMemo(
    () => <Illustration illustration={illustration} />,
    [illustration]
  )

  const iconColor =
    illustration === 'none' ? iconColors[variant ?? 'default'] : 'text-white'

  return (
    <div className='relative'>
      <div
        style={{
          height: size,
          width: size,
        }}
        className='absolute grid place-items-center'
      >
        <div className='absolute'>
          {pattern === 'rings' ? (
            <RingBgMd
              className={cn(
                'text-[#EAECF0] absolute -translate-x-1/2 -translate-y-1/2',
                ringBgClassName
              )}
            />
          ) : (
            <SquareBgMd
              className={cn(
                'text-[#EAECF0] absolute -translate-x-1/2 -translate-y-1/2',
                ringBgClassName
              )}
            />
          )}
        </div>
        {IllustrationGraphic ? (
          <div className='absolute'>
            <div className='mb-[30%]'>{IllustrationGraphic}</div>
          </div>
        ) : null}
      </div>
      <div
        className={cn('relative grid place-items-center ', {
          'rounded-xl border border-solid border-gray-200 bg-white shadow-xs':
            illustration === 'none',
          'rounded-full bg-gray-700/40 text-white': illustration !== 'none',
        })}
        style={{
          height: size,
          padding: padding ?? '0.75rem',
          width: size,
        }}
      >
        <Icon className={cn(iconColor)} {...iconProps} />
      </div>
    </div>
  )
}

export default IconRingWrapper
