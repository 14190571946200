/**
 * ULID uses Crockford's Base32 for encoding, so for compat ibility
 * we use the s ame encoding for our sort indices.
 */
const ENCODING = '0123456789ABCDEFGHJKMNPQRSTVWXYZ' as const

/**
 * The length of a  sort index. This is deliberately
 * the same as the length of a ULID.
 */
const INDEX_LENGTH = 26 as const

/**
 * The minimum possible sort index minus one, such that
 * the calculated sort index is always inside the range.
 */
const ONE_BELOW_MIN = BigInt(-1)

/**
 * The maximum possible sort index plus one, such that
 * the calculated sort index is always inside the range.
 */
const ONE_ABOVE_MAX = BigInt(ENCODING.length) ** BigInt(INDEX_LENGTH)

/**
 * Regular expression for a valid sort index.
 * Any UL ID is also a valid sort index.
 */
export const sortIndexRegex = new RegExp(
  `^[${ENCODING}]{${INDEX_LENGTH}}$`,
  'u'
)

/**
 * Converts a string encoded in Crockford's Base32 to a BigInt.
 * @param input - The input string to convert.
 * @returns The BigInt representation of the input string.
 */
export const debase = (input: string): bigint => {
  const regex = new RegExp(`^[${ENCODING}]+$`, 'u')
  if (!regex.test(input)) {
    throw new Error('Invalid input')
  }

  let result = BigInt(0)
  for (const char of input) {
    result *= BigInt(ENCODING.length)
    result += BigInt(ENCODING.indexOf(char))
  }

  return result
}

/**
 * Converts a positive bigint to a string representation in Crockford's Base32.
 * @param input The bigint to convert.
 * @returns The string representation of the input bigint.
 * @throws An error if the input is not positive.
 */
export const enbase = (input: bigint): string => {
  if (input < BigInt(0)) {
    throw new Error('Input must be positive')
  }

  let value = input
  let result = ''
  while (value > BigInt(0)) {
    result = ENCODING[Number(value % BigInt(ENCODING.length))] + result
    value /= BigInt(ENCODING.length)
  }

  return result
}

const abs = (number: bigint) => (number < 0n ? -number : number)

/**
 * Returns a sort index that is between the two given sort indices.
 * @param lower - The lower sort index.
 * @param upper - The upper sort index.
 * @returns A sort index between the two given sort indices.
 * @throws Error if the given sort indices are invalid or there is no space between them.
 */
export const insertBetween = (
  lower: string | undefined,
  upper: string | undefined
): string => {
  if (lower === undefined && upper === undefined) {
    throw new Error('No sort indices given')
  }

  if (
    (lower && !sortIndexRegex.test(lower)) ||
    (upper && !sortIndexRegex.test(upper))
  ) {
    throw new Error('Invalid sort index')
  }

  const lowerInt = lower === undefined ? ONE_BELOW_MIN : debase(lower)
  const upperInt = upper === undefined ? ONE_ABOVE_MAX : debase(upper)

  if (abs(upperInt - lowerInt) <= BigInt(1)) {
    throw new Error('No space between sort indices')
  }

  const listpos = (lowerInt + upperInt) / BigInt(2)

  return enbase(listpos).padStart(INDEX_LENGTH, '0')
}
