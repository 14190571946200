import { useI18nContext } from '@packages/i18n'
import type { AppRouterError } from '@types'
import ErrorState from './ErrorState'
import UnauthorizedState from './UnauthorizedState'

type ErrorResponsePreviewProps = {
  readonly error: AppRouterError
  readonly headlineOverride?: string
  readonly showErrorMessage?: boolean
}

const ErrorResponsePreview = ({
  error,
  headlineOverride,
  showErrorMessage,
}: ErrorResponsePreviewProps) => {
  const { LL } = useI18nContext()

  const message =
    error && 'message' in error && typeof error.message === 'string'
      ? error.message
      : null

  if (error.data?.code === 'FORBIDDEN') {
    return <UnauthorizedState />
  }

  return (
    <ErrorState
      error={error}
      headline={headlineOverride ?? LL.error.unknownError()}
      isNotFoundError={error.data?.code === 'NOT_FOUND'}
      description={showErrorMessage && message ? message : undefined}
    />
  )
}

export default ErrorResponsePreview
