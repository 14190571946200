import { useI18nContext } from '@packages/i18n'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalizationSettings } from './useLocalizationSettings'

/**
 * @returns a function that reloads the current page or navigates to the given path
 */
export const useReload = () => {
  const navigate = useNavigate()

  const { currentLocale } = useLocalizationSettings()
  const { setLocale, locale: contextLocale } = useI18nContext()

  useEffect(() => {
    if (contextLocale !== currentLocale) setLocale(currentLocale)
  }, [currentLocale, contextLocale, setLocale])

  const reload = useCallback(
    (to?: string) => {
      const target =
        !to || to.startsWith('/reload') ? window.location.pathname : to

      navigate(`/reload?location=${encodeURIComponent(target)}`, {
        replace: true,
      })
    },
    [navigate]
  )

  return reload
}
