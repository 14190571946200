export * as Colors from './colors'
export * from './legal'
export * as ThemeConstants from './theme-constants'

export const convertToMantineColorDefinition = (
  tailwindColor: Record<string, string>
) => {
  const sortedKeys = Object.keys(tailwindColor)
    .filter((key) => key !== 'DEFAULT')
    .sort((colorA, colorB) => Number(colorA) - Number(colorB))
    .filter((key) => Number(key) >= 50)

  const colors: string[] = []

  for (const key of sortedKeys) {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    if (colors.length <= 10) colors.push(tailwindColor[key]!)
  }

  if (colors.length < 10) {
    colors.push(
      ...(Array.from({ length: 10 - colors.length }).fill(
        colors.at(-1)
      ) as string[])
    )
  }

  return colors as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ]
}
