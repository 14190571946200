import { useProvideAuth, useTenant } from '@hooks'
import { LoadingOverlay } from '@mantine/core'
import type { DefaultAuthInfo } from '@types'
import { addAmplifyConfiguration } from '@utils/auth-utils'
import type { ReactElement, ReactNode } from 'react'
import { AuthContext } from './AuthContext'

type AuthProviderProps = {
  readonly children: ReactNode
}

const AuthProviderContent = (
  props: AuthProviderProps & { readonly authInfo: DefaultAuthInfo }
): ReactElement => {
  const value = useProvideAuth(props.authInfo)

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}

const AuthProvider = (props: AuthProviderProps): ReactElement => {
  const { defaultAuthInfo } = useTenant()
  if (!defaultAuthInfo) {
    return (
      <LoadingOverlay
        id='AuthProvider.defaultAuthInfo'
        title='Authentication Loading Spinner'
        visible
      />
    )
  }

  addAmplifyConfiguration(defaultAuthInfo.authInfo)

  return (
    <AuthProviderContent authInfo={defaultAuthInfo.authInfo}>
      {props.children}
    </AuthProviderContent>
  )
}

export default AuthProvider
