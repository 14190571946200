import { isLocalhost, resolveDeploymentStage } from '@packages/constants'
import {
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/react'

const { hostname } = window.location

export const initSentry = () =>
  init({
    dsn: 'https://ba81ff8ea29e91fc158161509f3d27ee@o4505838077542400.ingest.sentry.io/4505838081933312',
    enabled: !isLocalhost(hostname),
    environment: resolveDeploymentStage(hostname).stage,
    integrations: [browserTracingIntegration(), replayIntegration()],
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1,
  })
