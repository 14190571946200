import { Button, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { useState } from 'react'
import { z } from 'zod'

type TenantAliasFormProps = {
  readonly onSubmit: (value: { alias: string }) => Promise<boolean | undefined>
}

const TenantAliasForm = ({ onSubmit }: TenantAliasFormProps) => {
  const { LL } = useI18nContext()

  const aliasSchema = z.object({
    alias: z.string().min(3, {
      message: LL.validation.tooShort(3),
    }),
  })

  const form = useForm({
    initialValues: {
      alias: '',
    },
    validate: zodResolver(aliasSchema),
  })

  const [isLoading, setIsLoading] = useState(false)

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        if (isLoading) return

        setIsLoading(true)
        const result = await onSubmit(values)
        setIsLoading(false)

        if (result === undefined) {
          form.setFieldError('alias', LL.auth.errors.aliasNotFound())
        }
      })}
    >
      <TextInput
        label={LL.auth.tenantAliasLabel()}
        mt='md'
        size='md'
        variant='default'
        {...form.getInputProps('alias')}
        mb='md'
      />
      <Button fullWidth loading={isLoading} mt='3rem' size='md' type='submit'>
        {LL.buttons.continue()}
      </Button>
    </form>
  )
}

export default TenantAliasForm
