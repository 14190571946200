import type { BaseTranslation } from 'typesafe-i18n'

const en_settings = {
  settingsPage: {
    headline: 'Settings',
    methodology: {
      checkinDueFrequencies: {
        biweekly: 'Biweekly (recommended)',
        headline: 'Check-in due frequency',
        intro: 'Choose how often check-ins are due',
        monthly: 'Monthly',
        off: 'Never',
        weekly: 'Weekly',
      },
      errors: {
        updateFailed: 'Failed to update methodology settings',
      },
      headline: 'Methodology',
      intro:
        'Customize how you apply the AOA methodology in your organization. These settings apply to all members of the organization.',
      iterationCycles: {
        cycleStartDateDescription:
          'You may use this if your fiscal year does not start on January 1st.',
        cycleStartDateLabel: 'Yearly iteration start date',
        cycleStartDatePlaceholder: 'Default (January 1st)',
        earlyTermination:
          'This change will cause the current iteration to end early on {0:string}.',
        headline: 'Iteration cycles',
        intro:
          'Define when your iteration cycles start and end. This will not affect past iterations.',
        iterationNamePlaceholder: 'Default',
        legacyCycle: 'Legacy cycle',
        monthIteration: '{year:number} - Monthly iteration {month:number}',
        monthIterationNameLabel: 'Monthly iteration name',
        quarterIterationNameLabel: 'Quarterly iteration name',
        quaterIteration: '{year:number} - Quarterly iteration {quater:number}',
        yearlyIteration: 'Yearly iteration {0:string}',
        yearlyIterationNameLabel: 'Yearly iteration name',
      },
      updateSuccess: 'Methodology settings updated',
    },
    notifications: {
      checkins: {
        frequencies: {
          biweekly: 'Biweekly',
          inherited: 'Default',
          inheritedDescription: 'Use your organization default setting',
          monthly: 'Monthly',
          off: 'Do not notify me',
          weekly: 'Weekly',
        },
        headline: 'Check-In Reminders',
        intro: 'Receive a reminder to check-in if you have not done so',
      },
      headline: 'Notifications',
      subheader: 'Customize how you receive notifications',
    },
    organization: {
      communication: {
        checkinReminderEmails: 'Check-in reminder emails',
        defaultReminderInterval: 'Default reminder interval',
        defaultReminderIntervalDescription:
          'The default reminder interval for organization members',
        failedToUpdate: 'Failed to update communication settings',
        headline: 'Communication',
        intro: 'Update your organization communication settings',
        reminderEmailsDescription:
          'Send recurring reminder emails to inform members of missing check-ins',
        sendMonthlyAtEnd: 'Use end of month',
        sendMonthlyAtStart: 'Use start of month',
        timeOfDay: 'Time of day when reminder emails are sent',
        timeOfDayDescription: 'Time is specified in your local timezone',
        updated: 'Communication settings updated',
        weekDay: 'Day of week',
        weekDayDescription: 'The day of week when reminder emails are sent',
      },
      displayName: {
        displayName: 'Display name',
        failedToUpdate: 'Failed to update organization name',
        headline: 'Organization name',
        intro: 'How your organization is displayed in the app',
        updated: 'Organization name updated',
      },
      domain: {
        addAdditionalDomain: 'Add additional domain',
        createNewDomain: 'Create new domain',
        createNewDomainDescription:
          "Allow your organization's members to sign in using a unique URL.",
        domainLabel: 'Domain',
        headline: 'Organization domain',
        intro: 'A unique sign-in URL for your organization',
        limitReached: 'You have reached the limit of domains you can create',
        noDomainsCreatedDescription: "You haven't created any domains yet",
        noDomainsCreatedTitle: 'No domains created',
        subdomainAlreadyExists: 'This subdomain already exists',
      },
      msTeamsConnections: {
        noTeamsConnnectionsCreatedDescription:
          "You haven't set up any connections to Microsoft Teams yet.",
        noTeamsConnnectionsCreatedTitle: 'No Teams integration set-up',
        headline: 'Microsoft Teams Integration',
        intro: 'Connect your organization to Microsoft Teams',
        commentLabel: 'Comment',
        downloadApp: 'Download app',
        manageConnectionHeader: 'Manage Microsoft Teams Connection',
        addAppToTeamsHeader: 'Add GrowthSquare to Microsoft Teams',
        authenticateTeamsHeader: 'Authorize access from Microsoft Teams',
        introDescription:
          'Allow your organization members to interact with your organizations strategy through an AI-powered chatbot in Microsoft Teams.',
      },
      headline: 'Organization',
      logo: {
        failedToUpdate: 'Failed to update organization logo',
        headline: 'Organization logo',
        intro: 'Upload a logo for your organization to be displayed in the app',
      },
      sso: {
        headline: 'Single sign-on',
        initialRolesLabel: 'Initial Role',
        intro:
          "Allow your organization's members to sign in using their central login.",
        noSsoConfiguredDescription: "SSO isn't setup for your organization yet",
        noSsoConfiguredTitle: 'No SSO configured',
        providerLabel: 'Provider',
        scim: {
          baseUrl: 'Endpoint Base URL',
          configureScimHeader: 'Configure Directory Syncronization',
          configureScimIntro:
            'Use SCIM to sync users from your IdP. This will allow you to select users that do not use the platform yet.',
          configureScimMenuItem: 'Configure Directory Syncronization',
          enabledAt: 'Enabled at {0: string}',
          renewConfirm:
            "Are you sure you want to renew the token? This will invalidate the old token and you'll have to update it in your IdP.",
          renewToken: 'Renew Token',
          scimNotConfiguredDescription:
            'Configure a SCIM v2 endpoint to sync users from your IdP.',
          scimNotConfiguredTitle: 'SCIM not configured',
          secretToken: 'Secret Token',
          setupScimCta: 'Setup SCIM',
        },
      },
      subheader: 'Update your organization information and details here',
      team: {
        addAnother: 'Add another',
        addToTeamModal: {
          description:
            'Add this user to one of the remaining teams as a direct member. Teams that the user is already a member of are not shown.',
          title: 'Add user to team',
        },
        headline: 'Team Management',
        inviteTeamMembersHeader: 'Invite Team Members',
        inviteTeamMembersIntro:
          'Cooperate with your team members on your organizations success.',
        removeFromTeamModal: {
          description:
            'This user will be removed from the team, keep in mind that the user will still be assigned to his outcomes and actions.',
          title: 'Are you sure?',
        },
        sendInvites: 'Send Invites',
        subheader:
          'Manage your team members and their account permissions here.',
      },
    },
    personal: {
      displayName: {
        headline: 'Your name',
        intro: 'How your name is displayed in the app',
      },
      externalManaged: {
        description:
          "Contact your organization's administrator if you need to update your account information.",
        headline: 'Your account is managed by an external provider',
      },
      headline: 'Account',
      language: 'Language',
      languageDescription: 'Customize how the app is displayed to you.',
      picture: {
        failedToUpdate: 'Failed to update profile picture',
        headline: 'Profile picture',
        intro: 'Upload a profile picture',
      },
      profile: {
        department: 'Department',
        departmentDescription: "The department you're working in",
        displayName: 'Full name',
        email: 'Email address',
        failedToUpdate: 'Failed to update personal information',
        firstName: 'First name',
        headline: 'Personal information',
        intro: 'Update your personal information',
        lastName: 'Last name',
        phoneNumber: 'Phone number',
        updated: 'Personal information updated',
      },
      subheader: 'Update your personal information and preferences here',
    },
    subheader: 'Customize your experience',
  },
} as const satisfies BaseTranslation

export default en_settings
