import { Button, PasswordInput, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { useCallback } from 'react'
import { z } from 'zod'

type PickNewPasswordProps = {
  readonly errorMessage: string | undefined
  readonly isLoading: boolean
  readonly onSubmit: (password: string) => void
}

const loginSchema = z.object({
  confirmPassword: z
    .string()
    .min(8, { message: 'Password should have at least 8 characters' }),
  password: z
    .string()
    .min(8, { message: 'Password should have at least 8 characters' }),
})

const PickNewPassword = ({
  onSubmit,
  isLoading,
  errorMessage,
}: PickNewPasswordProps) => {
  const { LL } = useI18nContext()

  const form = useForm({
    initialValues: {
      confirmPassword: '',
      password: '',
    },
    validate: zodResolver(loginSchema),
  })

  const handleSubmit = useCallback(
    (values: { confirmPassword: string; password: string }) => {
      if (values.password === values.confirmPassword) {
        onSubmit(values.password.trim())
      } else {
        form.setFieldError('confirmPassword', 'Passwords do not match')
      }
    },
    [form, onSubmit]
  )

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <PasswordInput
        label={LL.auth.passwordLabel()}
        mt='md'
        size='md'
        variant='default'
        {...form.getInputProps('password')}
        autoComplete='new-password'
        mb='md'
      />
      <PasswordInput
        label={LL.auth.confirmPasswordLabel()}
        mt='md'
        placeholder=''
        size='md'
        variant='default'
        {...form.getInputProps('confirmPassword')}
        autoComplete='new-password'
        mb='md'
      />
      <Text c='red' className='min-h-[30px]' fw='600' mt='sm' size='md'>
        {errorMessage}
      </Text>
      <Button fullWidth loading={isLoading} mt='3rem' size='md' type='submit'>
        {LL.auth.changePasswordButton()}
      </Button>
    </form>
  )
}

export default PickNewPassword
