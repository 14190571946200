import TenantAvatar from '@components/organization/tenant/TenantAvatar'
import { Flex, Loader, Text } from '@mantine/core'
import { IconCheck, IconSelector } from '@tabler/icons-react'
import type { TenantLoginInfo } from '@types'
import { cn } from '@utils'
import type { ReactNode } from 'react'

type TenantListItemProps = {
  readonly dark?: boolean
  readonly disabled?: boolean
  readonly isCollapsed?: boolean
  readonly isLoading?: boolean
  readonly isSelected?: boolean
  readonly px?: string
  readonly py?: string
  readonly subText?: ReactNode
  readonly tenantInfo: TenantLoginInfo
  readonly withSelector?: boolean
}

const TextContent = ({
  displayName,
  subText,
  dark,
  disabled,
}: {
  readonly dark?: boolean
  readonly disabled?: boolean
  readonly displayName?: ReactNode
  readonly subText?: ReactNode
}) => (
  <Flex className='ml-3 mr-1 min-w-0 grow' direction='column'>
    <Text
      aria-label='organization-name'
      c={disabled ? 'gray.3' : dark ? 'white' : 'gray.7'}
      fw='600'
      size='sm'
      truncate='end'
    >
      {displayName}
    </Text>
    {subText && (
      <Text
        aria-label='organization-information'
        c={disabled ? 'gray.3' : dark ? 'primary.2' : 'gray.7'}
        fw='400'
        size='xs'
        truncate='end'
      >
        {subText}
      </Text>
    )}
  </Flex>
)

const TenantListItem = ({
  tenantInfo,
  subText,
  dark,
  px,
  py,
  isSelected,
  isLoading,
  withSelector,
  disabled,
  isCollapsed,
}: TenantListItemProps) => (
  <Flex
    align='center'
    className={cn('min-h-[40px] w-full transition-colors', {
      'cursor-not-allowed': disabled,
      'cursor-pointer': !disabled,
      'hover:bg-gray-100': !(dark || disabled),
      'hover:bg-white/5': dark && !disabled,
    })}
    px={px ?? 'xs'}
    py={py ?? 'xs'}
  >
    {tenantInfo && (
      <TenantAvatar
        authInfo={tenantInfo}
        avatarProps={{
          autoContrast: false,
          className: cn('size-10 shrink-0', {
            'bg-primary-200/15': dark,
          }),
          color: dark ? 'white' : undefined,
          opacity: disabled ? 0.5 : 1,
        }}
      />
    )}
    <div
      className={cn('flex w-full gap-x-1 transition-opacity', {
        'group-hover:opacity-100 opacity-0': isCollapsed,
        'opacity-100': !isCollapsed,
      })}
    >
      <TextContent
        dark={dark}
        disabled={disabled}
        displayName={tenantInfo?.displayName}
        subText={subText}
      />
      {isSelected && (
        <IconCheck className='ml-1 text-gray-800' size={26} stroke={2.5} />
      )}
      {isLoading && (
        <Loader c='gray' className='no-delay my-auto ml-auto' size={26} />
      )}
      {withSelector && (
        <IconSelector className='min-w-[21px] text-gray-500' size='1.3rem' />
      )}
    </div>
  </Flex>
)

export default TenantListItem
