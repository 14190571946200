import { trpc } from '@utils/trpc'
import { useCallback, useMemo } from 'react'
import { useAuth } from './useAuth'
import { useSettingsLocalStorage } from './useSettingsLocalStorage'

export const useLocalizationSettings = () => {
  const { localLanguage, setLocalLanguage } = useSettingsLocalStorage()

  const { user, reloadAccount } = useAuth()

  const { mutateAsync } = trpc.account.updateSettings.useMutation()

  const browserLocaleSettings = useMemo(() => {
    const browserLocales = navigator.languages ?? [navigator.language]
    if (!browserLocales) {
      return undefined
    }

    const availableLocales = browserLocales.map(
      (locale) => locale.trim().split(/-|_/u)[0]
    )

    return {
      availableLocales,
      defaultLocale: availableLocales[0],
    }
  }, [])

  const getCurrentLocale = useCallback(
    (newAccountLocale?: string) => {
      let locale: 'de' | 'en' = 'en'

      const accountLanguage =
        newAccountLocale ?? user?.account?.settings.language

      if (
        accountLanguage &&
        accountLanguage !== 'auto' &&
        ['de', 'en'].includes(accountLanguage)
      ) {
        locale = accountLanguage as 'de' | 'en'
      } else if (
        !localLanguage &&
        browserLocaleSettings?.defaultLocale &&
        ['de', 'en'].includes(browserLocaleSettings.defaultLocale)
      ) {
        locale = browserLocaleSettings.defaultLocale as 'de' | 'en'
      } else if (localLanguage === 'de') {
        locale = 'de'
      }

      return locale
    },
    [
      browserLocaleSettings?.defaultLocale,
      user?.account?.settings.language,
      localLanguage,
    ]
  )

  const currentLocale = useMemo(() => getCurrentLocale(), [getCurrentLocale])

  const updateLanguage = async (newLanguage: 'auto' | 'de' | 'en') => {
    if (user) {
      await mutateAsync({
        language: newLanguage,
      })
    }

    setLocalLanguage(newLanguage)

    if (user) await reloadAccount({ forceRefresh: true })

    return getCurrentLocale(newLanguage)
  }

  return {
    currentLocale,
    updateLanguage,
  }
}
