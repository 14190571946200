import { Anchor, Button, PasswordInput, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { PasswordRepeatShape } from '@packages/shapes'
import { ReactTranslation } from '@utils'
import { z } from 'zod'

type SignUpFormProps = {
  readonly error?: string
  readonly forcedUsername?: string
  readonly isLoading: boolean
  readonly onSignIn: () => void
  readonly onSubmit: (args: { password: string; username: string }) => void
}

const SignUpForm = ({
  onSubmit,
  isLoading,
  error,
  onSignIn,
  forcedUsername,
}: SignUpFormProps) => {
  const { LL } = useI18nContext()

  const loginSchema = PasswordRepeatShape(
    LL,
    z.object({
      username: z.string().email(LL.auth.validation.invalidEmail()),
    })
  )

  const form = useForm({
    initialValues: {
      confirmPassword: '',
      password: '',
      username: forcedUsername ?? '',
    },
    validate: zodResolver(loginSchema),
  })

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          disabled={Boolean(forcedUsername)}
          label={LL.auth.emailLabel()}
          mt='md'
          placeholder={LL.auth.placeholders.email()}
          size='md'
          type='email'
          {...form.getInputProps('username')}
          autoComplete='email'
          id='email'
          mb='md'
        />
        <PasswordInput
          label={LL.auth.passwordLabel()}
          mt='md'
          placeholder={LL.auth.placeholders.createPassword()}
          size='md'
          {...form.getInputProps('password')}
          autoComplete='new-password'
          id='password'
          mb='md'
        />
        <PasswordInput
          label={LL.auth.confirmPasswordLabel()}
          mt='md'
          placeholder={LL.auth.placeholders.confirmPassword()}
          size='md'
          {...form.getInputProps('confirmPassword')}
          autoComplete='new-password'
          id='confirmPassword'
          mb='md'
        />
        {error && (
          <Text c='red' fw='600'>
            {error}
          </Text>
        )}
        <Button fullWidth loading={isLoading} mt='3rem' size='md' type='submit'>
          {LL.auth.signUp()}
        </Button>
      </form>
      <Text c='gray.6' mt='xl' ta='center'>
        <ReactTranslation
          message={LL.auth.signInCta()}
          renderComponent={(label) => (
            <Anchor<'button'>
              c='primary.6'
              component='button'
              onClick={onSignIn}
              variant='subtle'
            >
              <Text fw='600'>{label}</Text>
            </Anchor>
          )}
        />
      </Text>
    </>
  )
}

export default SignUpForm
