import { useLocalStorage } from '@mantine/hooks'
import type { AuthSettings, TenantLoginInfo, UserAccountResponse } from '@types'
import {
  type AuthSession,
  type AuthSessionStorage,
  clearTokens,
  getUserMetaInfo,
} from '@utils/auth-utils'
import {
  authSessionShape,
  authSettings,
  deserializeLocalStorage,
  deserializeLocalStorageMap,
} from '@utils/localstorage-utils'
import type { fetchAuthSession } from 'aws-amplify/auth'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'
import type { SimpleUser } from './useProvideAuth'

export const useAuthLocalStorage = () => {
  const [tenantId, setTenantId] = useLocalStorage<string | undefined>({
    defaultValue: undefined,
    deserialize: (value) =>
      deserializeLocalStorage(value, z.string().ulid(), undefined),
    getInitialValueInEffect: false,
    key: 'tenant-id',
  })

  const [ssoConfiguration, setSsoConfiguration] = useLocalStorage<
    TenantLoginInfo['authSettings'] | null
  >({
    defaultValue: null,
    deserialize: (value) => deserializeLocalStorage(value, authSettings, null),
    getInitialValueInEffect: false,
    key: 'CognitoIdentityServiceProvider.SsoConfiguration.v2',
  })

  const enteredViaSSO = useMemo(
    () => window.location.search.includes('code='),
    []
  )

  const [sessions, setSessions] = useLocalStorage<AuthSessionStorage>({
    defaultValue: {},
    deserialize: (value) => deserializeLocalStorageMap(value, authSessionShape),
    getInitialValueInEffect: false,
    key: 'CognitoIdentityServiceProvider.AuthSessions.v2',
  })

  const addSession = useCallback(
    (props: {
      account: UserAccountResponse
      authSettings?: AuthSettings
      fetchAuthSessionResult: Awaited<ReturnType<typeof fetchAuthSession>>
      providerName?: string
      user: SimpleUser
    }) => {
      const username = props.user.username
      const userAttributes = props.user.attributes
      const userId = props.user.userId
      const meta = getUserMetaInfo(props.user, props.fetchAuthSessionResult)

      const updatedSession: AuthSession = {
        authenticated: true,
        authSettings: props.authSettings,
        lastRefreshTime: new Date().toISOString(),
        latestAccountInfo: props.account,
        meta,
        providerName: props.providerName,
        userAttributes,
        userId,
        username,
      }

      setSessions((previous) => ({
        ...previous,
        [username]: updatedSession,
      }))
    },
    [setSessions]
  )

  const removeSession = useCallback(
    ({ username }: { username: string }) => {
      clearTokens({ username })
      setSessions((previous) => {
        return {
          ...Object.fromEntries(
            Object.entries(previous).filter(([key]) => key !== username)
          ),
        }
      })
    },
    [setSessions]
  )

  const updateSession = useCallback(
    ({ username, data }: { data: Partial<AuthSession>; username: string }) => {
      setSessions((previous) => {
        const session = previous[username]
        if (!session) return previous

        const authenticated = data.authenticated ?? true

        return {
          ...previous,
          [username]: {
            ...session,
            ...data,
            authenticated,
            lastRefreshTime: new Date().toISOString(),
          },
        }
      })
    },
    [setSessions]
  )

  return {
    addSession,
    enteredViaSSO,
    removeSession,
    sessions: sessions ?? {},
    setSessions,
    setSsoConfiguration,
    setTenantId,
    ssoConfiguration,
    tenantId,
    updateSession,
  }
}
