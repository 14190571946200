import { useLocalStorage } from '@mantine/hooks'
import { deserializeLocalStorage } from '@utils/localstorage-utils'
import { z } from 'zod'

export const useSettingsLocalStorage = () => {
  const [localLanguage, setLocalLanguage] = useLocalStorage<string | undefined>(
    {
      defaultValue: undefined,
      deserialize: (value) =>
        deserializeLocalStorage(value, z.enum(['auto', 'de', 'en']), undefined),
      getInitialValueInEffect: false,
      key: 'language',
    }
  )

  return {
    localLanguage,
    setLocalLanguage,
  }
}
