import type { BaseTranslation } from 'typesafe-i18n'

const en_org = {
  organization: {
    createOrganization: {
      alreadyExists: 'An organization with this alias already exists.',
      button: 'Create organization',
      failedToCreate: 'Failed to create organization',
      headline: 'Welcome to GrowthSquare',
      headlineDescription: 'Create a new organization to get started.',
      initials: 'Initials',
      organizationName: 'Organization name',
      tenantAlias: 'Organization alias',
      tenantAliasExplanation:
        'The alias is used to create a unique URL for your organization. It can only contain lowercase letters, numbers and dashes.',
      tenantSubdomain: 'Organization subdomain',
      tenantSubdomainExplanation:
        'The subdomain is used to create a unique URL for your organization. It can only contain lowercase letters, numbers and dashes.',
    },
    inviteUser: 'Invite user',
    inviteUserModal: {
      inviteButton: 'Invite',
      inviteErrorDescription:
        'Please try again. If the problem persists, contact support.',
      inviteErrorHeadline: 'Failed to invite users',
      inviteSuccessDescription:
        'Successfully invited {0:number} user{{s}} to {1:string}',
      inviteSuccessHeadline: 'Users invited successfully',
      listHeader: {
        default: 'Select a person',
        keepTyping: 'Keep typing to invite email',
        selectUser: 'Select user to invite',
      },
      resultBox: {
        noSearchTerm: 'Search for users or paste emails above',
      },
      searchPlaceholder: 'Search name or emails',
      userAlreadyExists: 'This user is already a member of this organization.',
      userAlreadyExistsDetailed:
        '{name:string} is already a member of {org:string}',
      userAlreadyInvited:
        'This user has already been invited to this organization.',
    },
    memberCount: '{0:number} Member{{s}}',
    memberDetail: {
      accessRole: 'Access role',
      roles: {
        adminDescription: 'Can manage all organization settings',
        adminLabel: 'Admin',
        noAccessDescription:
          'Only view member list, no access to organization data',
        noAccessLabel: 'No access',
        viewerDescription:
          'Can view everything in the organization and perform checkins',
        viewerLabel: 'Viewer',
      },
      updateUserRoleErrorDescription:
        'Please try again. If the problem persists, contact support.',
      updateUserRoleErrorTitle: 'Failed to update user',
      updateUserRoleSuccessDescription:
        'Successfully assigned {0:string} a new role',
      updateUserRoleSuccessTitle: 'User updated successfully',
    },
    orgChart: {
      addSubTeam: 'Add sub-team',
      addTeamMember: 'Add team member',
      addToTeamButton: 'Add to team',
      color: 'Color',
      createTeam: 'Create team',
      createTeamDescription: 'Create a new team for your organization',
      createTeamHeadline: 'New team',
      deleteTeam: 'Delete team',
      displayName: 'Team Name',
      inheritedFrom: 'Inherited from {0:string}',
      inheritSubTeamMembers: 'Inherit sub team members',
      inheritSubTeamMembersDescription:
        'All team members who belong to a sub-team will also belong to this team.',
      noTeamAssigned: 'No team assigned',
      noTeamLead: 'No team lead assigned',
      noTeamMembers: 'No team members',
      noTeamsCreatedDescription: 'Create a team to get started',
      noTeamsCreatedHeadline: 'No teams created',
      noTeamsCta: 'Get started',
      noTeamsFoundError: 'No teams found',
      teamLead: 'Team lead',
      teamMembers: 'Team members',
      teamMembersDescription:
        'The following are directly assigned to this team.',
      updateTeam: 'Edit team',
      updateTeamDescription: 'Update an existing team',
      updateTeamHeadline: 'Edit team',
      viewMultipleAoa: 'View {0:number} assigned AOAs',
      noLinkedAoas: 'No AOAs linked to this team',
      linkedAoas: 'Linked AOAs',
      parentTeam: 'Parent team',
      subTeams: 'Sub-teams',
      viewSingleAoa: 'View {0:string}',
    },
    pageHeader: 'Organization Members',
    pageSubheader: 'Manage your organization members here',
    team: {
      headline: 'Team',
      teamFailedToLoad: 'Failed to load team',
      teamNotFound: 'Team not found',
    },
    profile: {
      addToTeamButton: 'Add to team',
      displayName: 'Display name',
      email: 'Email',
      failedToUpdateTeamMemberships:
        'Failed to update team memberships, please try again',
      firstName: 'First name',
      headline: 'Profile',
      lastName: 'Last name',
      noTeamsAvailable: 'No teams available',
      phoneNumber: 'Phone number',
      teamMemberships: 'Team memberships',
      backToMembers: 'Back to members',
    },
    usersPageHeader: 'Users',
    userTable: {
      activeUsers: 'Active',
      allUsers: 'All users',
      byTeamListSubheader: "Filter users by team or search for a user's name",
      empty: 'No users found',
      emptyInviteDescription:
        "You haven't invited any users to your organization yet",
      emptyState: {
        clearCategoryCta: 'Show all users',
        clearSearchCta: 'Clear search',
        descriptions: {
          emptyList: 'There are no valid users available.',
          noActiveUsers: 'There are no active users in your organization.',
          noInactiveUsers: 'There are no inactive users in your organization.',
          noInvitedUsers:
            'You have not invited any users to your organization yet.',
          noResults:
            'Your search “{searchQuery:string}” did not match any users. Please try again.',
          noResultsActiveUsers:
            'Your search “{searchQuery:string}” did not match any active users. Please try again.',
          noResultsInactiveUsers:
            'Your search “{searchQuery:string}” did not match any inactive users. Please try again.',
          noResultsInvitedUsers:
            'Your search “{searchQuery:string}” did not match any invited users. Please try again.',
        },
        inviteUsersCta: 'Invite new users',
        noResultsHeader: 'No users found',
      },
      inactiveUsers: 'Inactive',
      invitedUsers: 'Invited',
      membersWithoutTeams: 'Members without teams',
      menu: {
        activateUserConfirmation:
          "Are you sure you want to activate {0:string}'s user?",
        activateUserError: "User {0:string} couldn't be activated",
        activateUserLabel: 'Activate user',
        activateUserSuccess: 'User {0:string} was successfully activated',
        deleteInvitation: 'Delete invitation',
        disableUserConfirmation:
          "Are you sure you want to disable {0:string}'s user?",
        disableUserError: "User {0:string} couldn't be disabled",
        disableUserLabel: 'Disable user',
        disableUserSuccess: 'User {0:string} was successfully disabled',
        resendInvitation: 'Resend invitation',
        resendInvitationSkipped:
          'Invitation was already resent, please wait a few minutes before trying again.',
        resendInvitationSuccess: 'Invitation was resent successfully.',
        scimAccountInactive:
          'This user was deactivated in your identity provider. Contact support for more information.',
        userAccountInactive:
          "This user's account is disabled in our system. Contact support for more information.",
        viewProfile: 'View profile',
      },
      nameLabel: 'Name',
      roleLabel: 'Role',
      statusLabel: 'Status',
      teams: 'Teams',
      userCount: '{0:number} User{{s}}',
    },
  },
} as const satisfies BaseTranslation

export default en_org
