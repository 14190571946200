import { cn } from '@utils'
import type { HTMLAttributes } from 'react'

type OptimizedImageProps = {
  readonly alt: string
  readonly data: Array<{
    class?: string
    loading?: 'eager' | 'lazy'
    sizes: string
    src?: string
    srcset: string
    type: string
  }>
  readonly imageProps?: Omit<HTMLAttributes<HTMLImageElement>, 'alt' | 'src'>
  readonly pictureProps?: HTMLAttributes<HTMLPictureElement>
}

const OptimizedImage = ({
  data,
  alt,
  pictureProps,
  imageProps,
}: OptimizedImageProps) => {
  const lastSource = data.at(-1)

  return (
    <picture {...pictureProps}>
      {data.slice(0, -1).map((source) => {
        const { srcset, class: className, ...restSource } = source

        return (
          <source
            className={className}
            key={srcset}
            srcSet={srcset}
            {...restSource}
          />
        )
      })}
      <img
        src={lastSource?.src}
        {...imageProps}
        alt={alt}
        className={cn(lastSource?.class, imageProps?.className)}
      />
    </picture>
  )
}

export default OptimizedImage
