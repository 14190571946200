import { Divider, type DividerProps } from '@mantine/core'
import { type VariantProps, cva } from 'cva'

const classes = cva({
  base: 'col-span-full',
  defaultVariants: {
    size: 'xl',
  },
  variants: {
    size: {
      lg: 'my-10',
      md: 'my-8',
      sm: 'my-6',
      xl: 'my-12',
      xs: 'my-4',
    },
  },
})

const SettingsDivider = ({
  size,
  ...props
}: DividerProps & VariantProps<typeof classes>) => {
  return (
    <Divider
      className={classes({ className: props.className, size })}
      color='gray.2'
      {...props}
    />
  )
}

export default SettingsDivider
