import { useReload } from '@hooks'
import { useI18nContext } from '@packages/i18n'
import {
  IconAlertCircle,
  IconArrowBackUp,
  IconReload,
} from '@tabler/icons-react'
import type { AppRouterError } from '@types'
import { useNavigate } from 'react-router-dom'
import EmptyState from './EmptyState'

type ErrorStateProps = {
  readonly error?: AppRouterError
  readonly headline: string
  readonly isNotFoundError?: boolean
  readonly description?: string
}

const ErrorState = ({
  error,
  headline,
  isNotFoundError,
  description,
}: ErrorStateProps) => {
  const { LL } = useI18nContext()
  const navigate = useNavigate()
  const reload = useReload()

  const is404 = isNotFoundError ?? error?.data?.code === 'NOT_FOUND'

  return (
    <div className='mx-auto my-20 max-w-md'>
      <EmptyState
        cta={{
          icon: IconArrowBackUp,
          label: LL.errorPages.unauthorized.button(),
          onClick: () =>
            navigate('/', {
              replace: true,
            }),
        }}
        customIcon={IconAlertCircle}
        description={
          description ??
          (is404
            ? LL.errorPages.common.notFoundDescription()
            : LL.errorPages.common.failedToLoadDescription())
        }
        headline={headline}
        secondary={{
          icon: IconReload,
          label: LL.buttons.reloadPage(),
          onClick: () => reload(),
        }}
      />
    </div>
  )
}

export default ErrorState
