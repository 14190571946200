import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import { z } from 'zod'

/**
 * Requires a string to be of the format "YYYY-MM-DDTHH:mm:ss.SSSZ",
 * as returned by `new Date().toISOString()`.
 */
export const isoDatetime = () =>
  z.string().datetime({ offset: false, precision: 3 })

/**
 * Requires a string to be of the format "YYYY-MM-DD".
 */
export const isoDate = () => z.string().regex(/^\d{4}-\d{2}-\d{2}$/u)

export const PasswordRepeatShape = (
  LL: TranslationFunctions,
  base: z.AnyZodObject
) =>
  base
    .extend({
      confirmPassword: z
        .string()
        .min(8, { message: LL.validation.passwordMinLength(8) }),
      password: z
        .string()
        .min(8, { message: LL.validation.passwordMinLength(8) }),
    })
    .superRefine(({ confirmPassword, password }, context) => {
      if (confirmPassword !== password) {
        context.addIssue({
          code: 'custom',
          message: LL.validation.passwordsMustMatch(),
          path: ['confirmPassword'],
        })
      }
    })
