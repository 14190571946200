import { LanguageSelector } from '@components/settings'
import { ActionIcon, Box, Menu } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import { IconSettings } from '@tabler/icons-react'

const AccessibilitySettingsMenu = () => {
  const { LL } = useI18nContext()

  return (
    <Menu arrowOffset={13} position='right-end' shadow='md' withArrow>
      <Menu.Target>
        <ActionIcon aria-label='settings' c='gray.5' variant='white'>
          <IconSettings />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown aria-label='Settings Menu'>
        <Menu.Label>{LL.navigation.sidebar.settings()}</Menu.Label>
        <Box p='sm'>
          <LanguageSelector />
        </Box>
      </Menu.Dropdown>
    </Menu>
  )
}

export default AccessibilitySettingsMenu
