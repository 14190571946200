import useRouteData from '@hooks/useRouteData'
import { Text } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import { IconArrowLeft, IconChevronRight } from '@tabler/icons-react'
import { cn } from '@utils'
import type { ReactNode } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Chevron = () => (
  <IconChevronRight className='size-4 text-gray-300' size={16} />
)

const insertChevrons = (array: ReactNode[]) => {
  const result: ReactNode[] = []
  for (const [index, item] of array.entries()) {
    result.push(item)
    if (index !== array.length - 1) {
      result.push(<Chevron key={`chevron-${index}`} />)
    }
  }

  return result
}

type BreadcrumbsProps = {
  readonly className?: string
  readonly override?: Array<{
    index: number
    text?: string
  }>
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { matches, lastMatch } = useRouteData()

  const { LL } = useI18nContext()

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match, index) => (
      <NavLink
        className={({ isActive }) =>
          cn('grid h-7 px-2 place-items-center rounded-md', {
            'hover:bg-gray-50': !(
              isActive || match.handle?.crumbSettings?.disabled
            ),

            'pointer-events-none cursor-default':
              match.handle?.crumbSettings?.disabled,
            'text-gray-600': true,
          })
        }
        end
        key={`${match.pathname}:${Boolean(
          match.handle?.crumbSettings?.disabled
        ).valueOf()}`}
        to={match.pathname}
      >
        {props.override?.find((override) => override.index === index)?.text ??
          match.handle?.crumb?.({
            LL,
            loaderData: match.data,
          }).crumb}
      </NavLink>
    ))

  if (!crumbs.length) return null

  const mobileBackLink = lastMatch?.handle?.crumb?.({
    LL,
    loaderData: lastMatch.data,
  }).mobileBackLink

  return (
    <>
      <div
        className={cn(
          'select-none items-center space-x-0 -mx-2 text-sm font-semibold text-gray-500',
          props.className,
          'hidden sm:flex'
        )}
      >
        {insertChevrons(crumbs)}
      </div>
      {mobileBackLink && (
        <Link
          className='mb-5 flex space-x-2 sm:hidden'
          to={mobileBackLink.to ?? '..'}
        >
          <IconArrowLeft className='text-gray-600' size={20} stroke={2} />
          <Text c='gray.6' fw='700' size='sm'>
            {mobileBackLink.label}
          </Text>
        </Link>
      )}
    </>
  )
}

export default Breadcrumbs
