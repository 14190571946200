import { useProvideTenant } from '@hooks'
import type { ReactElement, ReactNode } from 'react'
import { TenantContext } from './TenantContext'

export const TenantProvider = (props: {
  readonly children: ReactNode
}): ReactElement => {
  const value = useProvideTenant()

  return (
    <TenantContext.Provider value={value}>
      {props.children}
    </TenantContext.Provider>
  )
}
