import type { GsPermission } from '@packages/permissions'
import { allPermissions } from '@packages/permissions/lib/roles/standard-roles'
import { TenantSettingsShape } from '@packages/shapes'
import type { AuthSettings, TenantLoginInfo, UserAccountResponse } from '@types'
import type { A } from 'ts-toolbelt'
import { z } from 'zod'
import { getCurrentAuthConfig } from './auth-utils'

export const deserializeLocalStorage = <
  TShape extends z.ZodTypeAny,
  TDefaultValue = unknown,
>(
  stringData: string | undefined,
  shape: TShape,
  defaultValue: TDefaultValue
): TDefaultValue | z.infer<TShape> => {
  if (!stringData) return defaultValue
  try {
    let result = defaultValue

    const data = JSON.parse(stringData as string) as unknown

    const isValid = shape.safeParse(data)
    if (isValid.success) {
      result = isValid.data
    }

    return result
  } catch {
    return defaultValue
  }
}

export const deserializeLocalStorageMap = <TShape extends z.ZodTypeAny>(
  stringData: string | undefined,
  shape: TShape
): Record<string, z.infer<TShape>> => {
  if (!stringData) return {}
  try {
    const result: Record<string, TShape> = {}

    const data = JSON.parse(stringData as string) as unknown
    if (typeof data === 'object' && data !== null) {
      for (const [key, value] of Object.entries(data)) {
        try {
          shape.parse(value)
        } catch (error) {
          // biome-ignore lint/suspicious/noConsole: useful error that should be logged
          console.error('deserializeLocalStorageMap', error, data)
        }

        const isValid = shape.safeParse(value)
        if (isValid.success) {
          result[key] = isValid.data
        }
      }
    }

    return result
  } catch {
    return {}
  }
}

export const authSettings: z.ZodType<AuthSettings> = z.object({
  flags: z.object({
    allowEmailPasswordAuth: z.boolean().optional(),
    allowSelfSignUp: z.boolean().optional(),
  }),
  identityProviders: z.array(
    z.object({
      displayName: z.string(),
      isEnabled: z.boolean(),
      providerName: z.string(),
      scimConfiguration: z.object({
        enabled: z.boolean(),
        modifiedAt: z.string().optional(),
      }),
    })
  ),
})

export const tenantInfoShape = z.object({
  alias: z.string(),
  authSettings,
  displayName: z.string(),
  initials: z.string().optional(),
  logoSquareUrl: z.string().optional(),
  settings: TenantSettingsShape.optional(),
  tenantId: z.string(),
})

type Expect<TValue extends 1> = TValue

type testTenantInfoMatch = Expect<
  A.Equals<z.infer<typeof tenantInfoShape>, TenantLoginInfo>
>

// @ts-ignore
// biome-ignore lint/correctness/noUnusedVariables: test
type ignoreMe = testTenantInfoMatch

const userTeamShape: z.ZodType<UserAccountResponse['teams'][0]> = z.object({
  memberCount: z.number(),
  permissions: z.array(
    z.enum(
      allPermissions as unknown as Readonly<[GsPermission, ...GsPermission[]]>
    )
  ),
  planTitle: z.string().optional(),
  tenant: tenantInfoShape,
  tenantId: z.string(),
})

const userAccountResponseShape = z.object({
  account: z.object({
    cognitoUsername: z.string(),
    settings: z.object({
      byTenant: z.record(z.any()).optional(),
      language: z.string().optional(),
    }),
    userId: z.string(),
    userPoolId: z.string(),
  }),
  invitations: z.array(
    z.object({
      invitation: z.object({
        createdByDisplayName: z.string().optional(),
        email: z.string(),
        modified: z.string(),
        roles: z.array(z.string()),
        tenantId: z.string(),
      }),
      memberCount: z.number(),
      planTitle: z.string().optional(),
      tenant: tenantInfoShape,
    })
  ),
  teams: z.array(userTeamShape),
})

const deleteLocalStorageWithPrefix = (prefix: string) => {
  // list all localstorage keys starting with CognitoIdentityServiceProvider
  const cognitoKeys = Object.keys(localStorage).filter(
    (key) => key.startsWith(prefix) && !key.includes('LastAuthUser')
  )
  // remove all keys
  for (const key of cognitoKeys) localStorage.removeItem(key)
}

export const clearLoginLocalStorage = (
  appClientId?: string,
  username?: string
) => {
  const key = `CognitoIdentityServiceProvider.${appClientId ?? ''}${
    appClientId && username ? `.${username}` : ''
  }`
  deleteLocalStorageWithPrefix(key)
}

const storageShape = z.object({
  timestamp: z.number(),
  value: z.unknown(),
})

export const getLoaderLocalStorageKey = (link: string) =>
  `loader-storage-${link}`

export const getLoaderLocalStorageValue = (link: string) => {
  const key = getLoaderLocalStorageKey(link)
  try {
    const value = localStorage.getItem(key) ?? ''
    const parsed = deserializeLocalStorage(value, storageShape, undefined)
    if (parsed && parsed.timestamp + 1_000 * 30 * 1 > Date.now()) {
      return parsed.value
    }
  } catch {
    return undefined
  }

  return undefined
}

export const checkIfPrefetching = (link: string) => {
  const key = `${getLoaderLocalStorageKey(link)}-prefetching`
  return localStorage.getItem(key) === 'true'
}

const userAttributesShape = z.object({
  address: z.string().optional(),
  birthdate: z.string().optional(),
  'custom:department': z.string().optional(),
  email: z.string().optional(),
  email_verified: z.string().optional(),
  family_name: z.string().optional(),
  gender: z.string().optional(),
  given_name: z.string().optional(),
  locale: z.string().optional(),
  middle_name: z.string().optional(),
  name: z.string().optional(),
  nickname: z.string().optional(),
  phone_number: z.string().optional(),
  phone_number_verified: z.string().optional(),
  picture: z.string().optional(),
  preferred_username: z.string().optional(),
  profile: z.string().optional(),
  sub: z.string().optional(),
  updated_at: z.string().optional(),
  website: z.string().optional(),
  zoneinfo: z.string().optional(),
})

export const authSessionShape = z.object({
  authenticated: z.boolean(),
  authSettings: authSettings.optional(),
  lastRefreshTime: z.string().datetime(),
  latestAccountInfo: userAccountResponseShape,
  meta: z
    .object({
      allowCreateTenant: z.boolean().optional(),
      isExternalManaged: z.boolean().optional(),
      isFederated: z.boolean().optional(),
      userSetupFinished: z.boolean().optional(),
    })
    .optional(),
  providerName: z.string().optional(),
  userAttributes: userAttributesShape,
  userId: z.string(),
  username: z.string(),
})

export const getCurrentAmplifyUsername = () => {
  const { appClientId } = getCurrentAuthConfig()

  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${appClientId}.LastAuthUser`
  )
}

export const setCurrentAmplifyUser = ({
  username,
}: {
  username: string | undefined
}) => {
  const { appClientId } = getCurrentAuthConfig()

  const key = `CognitoIdentityServiceProvider.${appClientId}.LastAuthUser`

  if (username) {
    localStorage.setItem(key, username)
  } else {
    localStorage.removeItem(key)
  }
}
