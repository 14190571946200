export enum AoaItemType {
  Intent = 'intent',
  Outcome = 'outcome',
  Text = 'text',
  Action = 'action',
}

export const KpiComparisonOptions = [
  'equal',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
] as const

export type KpiComparison = (typeof KpiComparisonOptions)[number]

// Not Started, On Track, Delayed, Blocked, Cancelled, Done
export enum AoaProgressStatus {
  Blocked = 'blocked',
  Cancelled = 'cancelled',
  Delayed = 'delayed',
  Done = 'done',
  OnTrack = 'on track',
}

export type QuantifierUnit = {
  allowDecimals: boolean
  symbol: string
  symbolPosition: 'BEFORE' | 'AFTER'
}

export type AoaItem<T extends AoaItemType = AoaItemType> = AoaEditable &
  AoaSortable & {
    aoaId: string
    copiedFromItem?: {
      aoaId: string
      itemId: string
      revision: string
    }
    description?: string
    importedFromAoaIds?: string[]
    itemId: string
    itemType: T
    movedToAoaId?: string
    parentItemId?: string
    /**
     * The IDs of the parent items, going down from some root item
     * to the parent of this item. Will be empty if this is a root.
     */
    parentItemIds: string[]
    periodEnd?: string
    periodStart?: string
    responsibleUserId?: string | null
    section?: AoaSection
    tags?: string[]
    tenantId: string
    text?: string
    results?: AoaResultDefinition[]
  }

export enum AoaSection {
  Beliefs = 'beliefs',
  Boundaries = 'boundaries',
  Challenges = 'challenges',
  Input = 'input',
  IntentsActions = 'intentsActions',
  LooseOutcomes = 'looseOutcomes',
  Mission = 'mission',
  StatusQuo = 'statusQuo',
  Vision = 'vision',
}

export type Iteration = {
  iterationCycleId: string
  iterationIntervalType: 'YEAR' | 'QUARTER' | 'MONTH'
  iterationSequenceNumber: number
  year: number
}

export type AoaInfo = AoaApprovable & {
  accountableTeamId?: string
  aoaId: string
  iteration?: Iteration
  level: number
  parentAoaId?: string
  /**
   * The IDs of the parent AOAs, going down from some root AOA
   * to the parent of this AOA. Will be empty if this is a root.
   */
  parentAoaIds: string[]

  /**
   * The ID of the series that this AOA belongs to.
   * A series is a collection of AOAs that are connected
   * in that they are sequential iterations of the same AOA.
   * Should be set to the first aoaId in the series.
   */
  seriesId?: string
  tenantId: string
  title?: string
}

export type AoaSortable = {
  /**
   * The sort index of this item, as a string.
   *
   * For items that are not explicitly sorted, the sort index is compatible
   * with the ulid of the item.
   */
  sortIndex?: string
}

export type AoaEditable = {
  /**
   * Some optional comment by the user who last updated this item.
   */
  comment?: string
  /**
   * The ID of the user who initially created this item.
   */
  createdByUserId?: string
  /**
   * Whether this item is currently deleted. Undefined means false.
   */
  isDeleted?: boolean
  /**
   * True only if this is the latest revision of this item.
   */
  isLatest: boolean
  /**
   * A monotonically increasing string for versioning.
   * We use the ISO timestamp format `YYYY-MM-DDTHH:mm:ss.SSSZ` that is
   * returned by the `Date.toISOString()` method.
   *
   * Note: There may be a slight difference between this and the
   * default attributes `created` and `modified`.
   */
  revision: string
  /**
   * The ID of the tenant that this item belongs to.
   */
  tenantId: string
  /**
   * A list of attributes that have been updated in this revision.
   * Not included if this is the first revision.
   * Also not included are attributes that have to do with the revision itself,
   * such as `revision` and `isLatest`.
   */
  updatedAttributes?: string[]
  /**
   * The ID of the user who last updated, deleted, or undeleted this item.
   */
  updatedByUserId?: string
}

export type AoaResultDefinition = {
  resultId: string
  title: string
  resultType: 'boolean' | 'kpi'
  priority: number // (=ordering)
  deleted?: boolean
  description?: string
  referenceLink?: string

  // for kpi values:
  startValue?: number | null
  targetValue?: number | null
  comperator?: KpiComparison | null
  unit?: QuantifierUnit | null
}

export type AoaCreateResultDefinition = Omit<AoaResultDefinition, 'resultId'>

export type AoaCheckinData = {
  resultId: string
  success: boolean | null
  value: number | null
  unchanged?: boolean // flag set by frontend
}

export type AoaCheckin = {
  checkinId: string
  aoaId: string
  itemId: string
  tenantId: string
  itemRevision: string
  userId: string
  checkinData: AoaCheckinData[]
  comment?: string
  status: AoaProgressStatus
  confidence: number
  progress: number
  timestamp: string
  // trend: 'positive' | 'negative' | 'neutral' | 'initial'
}

/**
 * Indicates the status of an AOA item.
 *
 * By default all items are in the `Draft` status, and
 * can be changed to `Pending` by the creator. A user with
 * the correct permissions can then approve the item, which
 * will change the status to `Approved`. If the item is
 * no longer relevant, it can be withdrawn, which will
 * change the status to `Withdrawn`. If the item is
 * out of date, it can be archived, which will
 * change the status to `Archived`.
 */
export enum AoaApprovableStatus {
  Approved = 'approved',
  Draft = 'draft',
  Withdrawn = 'withdrawn',
}

export type AoaApprovable = AoaEditable & {
  approvedAt?: string
  approvedByUserId?: string
  status: AoaApprovableStatus
  withdrawnAt?: string
  withdrawnByUserId?: string
}

/**
 * Reasons why an iteration is not available for an AOA.
 */
export enum IterationUnavailableReason {
  /**
   * The AOA already has a cycle that fully contains the iteration.
   */
  AoaCycleContainedByExisting = 'aoa-cycle-contained-by-existing',
  /**
   * The AOA already has a cycle with the exact same iteration.
   */
  AoaCycleExists = 'aoa-cycle-exists',
  /**
   * The parent AOA is not approved.
   */
  ParentAoaNotApproved = 'parent-aoa-not-approved',
}
