import { Button, Text, Title } from '@mantine/core'
import { getResolvedDomains } from '@packages/constants'
import { useI18nContext } from '@packages/i18n'

const TenantNotFoundPage = () => {
  const { LL } = useI18nContext()

  const { webRecord } = getResolvedDomains(window.location.hostname)

  return (
    <div className='grid h-screen place-items-center'>
      <div className='m-12 max-w-96'>
        <div>
          <Title mb={12} order={3}>
            {LL.errorPages.orgNotFound.headline()}
          </Title>
          <Text c='dimmed' mb={36} size='md'>
            {LL.errorPages.orgNotFound.description()}
          </Text>
          <Button<'a'> component='a' href={`https://${webRecord}`} size='md'>
            {LL.errorPages.orgNotFound.button()}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TenantNotFoundPage
