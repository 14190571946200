import { Text } from '@mantine/core'
import { cva } from 'cva'
import type { ReactNode } from 'react'

type SettingsRowProps = {
  readonly children: ReactNode
  readonly className?: string
  readonly description?: string
  readonly label: string
  readonly size?: 'md' | 'lg'
}

const classes = cva({
  base: 'grid gap-x-10 gap-y-5',
  defaultVariants: {
    size: 'md',
  },
  variants: {
    size: {
      lg: 'max-w-5xl md:grid-cols-[3fr_5fr]',
      md: 'max-w-4xl md:grid-cols-[3fr_4fr]',
    },
  },
})

const SettingsRow = ({
  children,
  label,
  description,
  size,
  className,
}: SettingsRowProps) => {
  return (
    <div className={classes({ className, size })}>
      <div>
        <Text c='gray.7' fw='600' size='sm'>
          {label}
        </Text>
        {description && (
          <Text c='dimmed' fw='400' size='sm'>
            {description}
          </Text>
        )}
      </div>
      <div className='max-w-2xl'>{children}</div>
    </div>
  )
}

export default SettingsRow
