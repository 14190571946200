import BrandLogo from '@components/branding/BrandLogo'
import { Dialog, Transition } from '@headlessui/react'
import ArrowLeft from '@icons/arrow-narrow-left.svg'
import { ActionIcon, Divider } from '@mantine/core'
import { IconMenu2, IconX } from '@tabler/icons-react'
import { cn } from '@utils'
import { Fragment, useState } from 'react'
import SidebarAccountMenu from './SidebarAccountMenu'
import SidebarMenu from './SidebarMenu'

type SidebarProps = {
  readonly isCollapsed: boolean
  readonly setCollapsed: (value: boolean) => void
}

const Sidebar = ({ isCollapsed, setCollapsed }: SidebarProps) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false)

  return (
    <>
      <Transition.Root as={Fragment} show={mobileSidebarOpen}>
        <Dialog
          as='div'
          className='relative z-50 lg:hidden'
          onClose={setMobileSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-950/80' />
          </Transition.Child>
          <div className='fixed inset-0 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                    <button
                      className='-m-2 cursor-pointer !border-none bg-transparent p-2'
                      onClick={() => setMobileSidebarOpen(false)}
                      type='button'
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <IconX
                        aria-hidden='true'
                        className='size-6 text-gray-100'
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className='flex grow flex-col gap-y-6 overflow-y-auto bg-primary-950 ring-1 ring-white/10'>
                  <div className='mt-4 flex shrink-0 items-center px-4'>
                    <BrandLogo className='my-auto h-9 w-auto' type='full' />
                  </div>
                  <nav className='flex flex-1 flex-col'>
                    <div className='flex flex-1 flex-col gap-y-7'>
                      <div className='flex flex-1 flex-col'>
                        <div className='space-y-1 px-2'>
                          <SidebarMenu
                            allowOpenMenu
                            onNavigate={() => setMobileSidebarOpen(false)}
                          />
                        </div>
                        <div className='mt-auto'>
                          <div>
                            <Divider color='white' opacity={0.2} />
                          </div>
                          <div>
                            <SidebarAccountMenu />
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div
        className={cn(
          'hidden select-none overflow-hidden transition-[width] duration-300 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col',
          {
            'w-sidebar-closed hover:w-sidebar-open': isCollapsed,
            'w-sidebar-open': !isCollapsed,
          }
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='group flex grow flex-col gap-y-6 overflow-y-auto overflow-x-hidden bg-primary-950'>
          <div className='mt-8 h-10 shrink-0 px-5'>
            <BrandLogo
              className='h-9 w-auto'
              fullClassName='ml-1'
              type={isCollapsed ? 'icon' : 'full'}
            />
          </div>
          <nav className='flex flex-1 flex-col'>
            <div className='flex flex-1 flex-col gap-y-7'>
              <div
                className={cn('pl-4', {
                  'pr-4 group-hover:pr-5': isCollapsed,
                  'pr-5': !isCollapsed,
                })}
              >
                <div className='space-y-1'>
                  <SidebarMenu isCollapsed={isCollapsed} />
                </div>
              </div>
              <div className='mt-auto'>
                <div className='p-4'>
                  <ActionIcon
                    color='white'
                    onClick={() => setCollapsed(!isCollapsed)}
                    size={40}
                    variant='subtle'
                  >
                    <ArrowLeft
                      aria-hidden='true'
                      className={cn('size-5 transition', {
                        'rotate-180': isCollapsed,
                      })}
                    />
                  </ActionIcon>
                </div>
                <div>
                  <Divider color='white' opacity={0.2} />
                </div>
                <div className='-mt-px'>
                  <SidebarAccountMenu isCollapsed={isCollapsed} />
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className='sticky top-0 z-40 flex items-center gap-x-6 bg-primary-950 p-4 shadow-sm sm:px-6 lg:hidden'>
        <ActionIcon
          className='hover:!bg-gray-800 lg:hidden'
          onClick={() => setMobileSidebarOpen(true)}
          type='button'
          variant='subtle'
        >
          <IconMenu2 aria-hidden='true' className='size-6' />
        </ActionIcon>
      </div>
    </>
  )
}

export default Sidebar
