import { openAddAccountModal } from '@components/auth'
import LoginLayout from '@components/layout/LoginLayout'
import TenantAvatar from '@components/organization/tenant/TenantAvatar'
import { useAuth } from '@hooks'
import { Button, Loader, Text, Title } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import type { TenantLoginInfo } from '@types'
import { ReactTranslation } from '@utils'
import { trpc } from '@utils/trpc'
import { type ReactNode, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'
import PickTenantPage from './PickTenantPage'

const InvitationPageLayout = ({
  children,
  tenantInfo,
}: {
  readonly children: ReactNode
  readonly tenantInfo?: TenantLoginInfo
}) => {
  const { LL } = useI18nContext()

  return (
    <LoginLayout>
      <div className='my-8'>
        {tenantInfo && (
          <div>
            <div className='grid place-items-center'>
              <TenantAvatar
                authInfo={tenantInfo}
                avatarProps={{
                  className: 'shadow-sm',
                  mb: 'lg',
                  size: 'lg',
                }}
              />
            </div>
            <ReactTranslation
              message={LL.auth.invitationWelcomeText(tenantInfo.displayName)}
              renderComponent={(messagePart) => <strong>{messagePart}</strong>}
            />
          </div>
        )}
      </div>
      {children}
    </LoginLayout>
  )
}

const shape = z.object({
  email: z.string().email(),
  tenantId: z.string(),
  userPoolId: z.string(),
})

const parseToken = (token: string | null) => {
  if (!token) return null
  try {
    const jsonString = atob(token)

    const parsed = JSON.parse(jsonString)
    const validated = shape.parse(parsed)
    return validated
  } catch {
    return null
  }
}

const InvitationPage = () => {
  const [urlSearchParameters] = useSearchParams()
  const token = urlSearchParameters.get('token')
  const parsed = parseToken(token)
  const { LL } = useI18nContext()
  const navigate = useNavigate()

  const { sessionRestoreFinished, switchUser, ssoSignIn, sessions } = useAuth()

  const { data: tenantInfo, isPending: tenantLoading } = trpc[
    'public-router'
  ].getAuthInfoByTenantId.useQuery(parsed?.tenantId ?? '', {
    enabled: Boolean(parsed?.tenantId),
  })

  const { data: userExists, isPending: isLoadingExistCheck } = trpc[
    'public-router'
  ].checkUserExists.useQuery(
    {
      username: parsed?.email ?? '',
      userPoolId: parsed?.userPoolId ?? '',
    },
    { enabled: Boolean(parsed) }
  )

  const isLoading = useMemo(() => {
    return !sessionRestoreFinished || tenantLoading || isLoadingExistCheck
  }, [isLoadingExistCheck, sessionRestoreFinished, tenantLoading])

  const availableUser = useMemo(() => {
    if (!parsed) return null

    if (!userExists) return null

    let availableUsername: string | null = null
    let userAuthenticated = false

    for (const [username, { authenticated, userAttributes }] of Object.entries(
      sessions
    )) {
      if (parsed.email === userAttributes.email) {
        availableUsername = username
        userAuthenticated = authenticated
        if (userAuthenticated) break
      }
    }

    return {
      user:
        availableUsername && availableUsername in sessions
          ? sessions[availableUsername]
          : null,
      userAuthenticated,
    }
  }, [parsed, sessions, userExists])

  useEffect(() => {
    if (availableUser?.userAuthenticated && availableUser?.user?.username) {
      const username = availableUser.user.username
      if (username) void switchUser({ navigate, username })
    }
  }, [
    availableUser?.user?.username,
    availableUser?.userAuthenticated,
    navigate,
    switchUser,
  ])

  if (!token) {
    navigate('/')
    return null
  }

  if (!parsed) {
    return (
      <LoginLayout>
        <Title pt='xl'>{LL.auth.invalidInvitation()}</Title>
        <Text>{LL.auth.invalidInvitationDescription()}</Text>
        <div className='text-center'>
          <Button<'a'> component='a' href='/' mt='xl'>
            {LL.auth.backToHome()}
          </Button>
        </div>
      </LoginLayout>
    )
  }

  if (isLoading || !tenantInfo) {
    return (
      <InvitationPageLayout tenantInfo={tenantInfo}>
        <Loader />
      </InvitationPageLayout>
    )
  }

  if (userExists?.exists === false) {
    return (
      <InvitationPageLayout tenantInfo={tenantInfo}>
        <Button
          onClick={() => {
            openAddAccountModal(
              {
                authflow: 'signup',
                loginInfo: tenantInfo,
                username: parsed.email,
              },
              false
            )
          }}
        >
          {LL.auth.invitationSignUp()}
        </Button>
      </InvitationPageLayout>
    )
  }

  if (availableUser?.userAuthenticated) {
    return <PickTenantPage />
  }

  if (userExists?.exists === true && userExists.providerName) {
    return (
      <InvitationPageLayout tenantInfo={tenantInfo}>
        <Button
          onClick={() => {
            if (userExists.providerName) {
              void ssoSignIn(userExists.providerName, tenantInfo.authSettings)
            }
          }}
        >
          {LL.auth.signInWithSso()}
        </Button>
      </InvitationPageLayout>
    )
  }

  if (userExists?.exists === true) {
    return (
      <InvitationPageLayout tenantInfo={tenantInfo}>
        <Button
          onClick={() => {
            openAddAccountModal(
              {
                loginInfo: tenantInfo,
                username: parsed.email,
              },
              false
            )
          }}
        >
          {LL.auth.invitationSignIn()}
        </Button>
      </InvitationPageLayout>
    )
  }

  // unhandled case
  navigate('/')

  return <Loader />
}

export default InvitationPage
