import XCloseIcon from '@icons/x-close.svg'
import {
  type MantineColorsTuple,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core'
import type { ExtendedCustomColors } from '@web/types/mantine'
import { type VariantProps, cva } from 'cva'
import type React from 'react'
import type { ReactNode } from 'react'

const defaultSize = 'md' as const

const badge = cva({
  base: 'flex items-center border border-solid text-sm font-medium w-fit',
  defaultVariants: {
    size: defaultSize,
    variant: 'pill',
  },
  variants: {
    size: {
      lg: 'h-7 px-3 py-0.5 text-sm',
      md: 'h-6 px-2 text-sm',
      sm: 'h-[22px] px-1.5 text-xs',
      xs: 'px-1.5 !text-[10px] leading-[15px]',
    },
    variant: { modern: 'shadow-xs rounded-md', pill: 'rounded-full' },
  },
})

const dot = cva({
  base: 'size-1.5 inline',
  defaultVariants: {
    size: defaultSize,
  },
  variants: {
    size: {
      lg: 'mr-1',
      md: 'mr-1',
      sm: 'mr-1.5',
      xs: 'mr-1.5',
    },
  },
})

const dismissButton = cva({
  base: 'size-4 grid place-items-center',
  defaultVariants: {
    size: defaultSize,
  },
  variants: {
    size: {
      lg: 'ml-0.5',
      md: 'ml-0.5',
      sm: 'ml-0.5',
      xs: 'ml-0.5',
    },
  },
})

export type GsBadgeProps = {
  readonly children: ReactNode
  readonly color?: ExtendedCustomColors
  readonly customColorScheme?: MantineColorsTuple
  readonly onClick?: () => void
  readonly onDismiss?: () => void
  readonly withDot?: boolean
} & Pick<React.ButtonHTMLAttributes<HTMLDivElement>, 'className'> &
  VariantProps<typeof badge>

const GsBadge = ({
  className,
  withDot,
  variant,
  size,
  children,
  customColorScheme,
  color,
  onDismiss,
  ...props
}: GsBadgeProps) => {
  const theme = useMantineTheme()

  if (!theme.colors) return null

  const customColors = color ? theme.colors[color] : null
  const gray = theme.colors.gray
  const scheme = customColorScheme ?? customColors ?? gray

  const dotColor = scheme[5]
  let labelColor = scheme[7]
  let outlineColor = scheme[2]
  let backgroundColor = scheme[0]
  // TODO: Add hover for dismiss
  const dismissColor = scheme[4]

  if (variant === 'modern') {
    labelColor = gray[7]
    outlineColor = gray[3]
    backgroundColor = '#fff'
  }

  return (
    <div
      className={badge({ className, size, variant })}
      onClick={props.onClick}
      style={{
        backgroundColor,
        borderColor: outlineColor,
        color: labelColor,
      }}
      {...props}
    >
      {withDot ? (
        <svg
          aria-hidden='true'
          className={dot({ size })}
          style={{
            fill: dotColor,
          }}
          viewBox='0 0 6 6'
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
      ) : null}
      {children}
      {onDismiss ? (
        <UnstyledButton
          className={dismissButton({ size })}
          color={dismissColor}
          onClick={onDismiss}
          type='button'
        >
          <XCloseIcon className='size-3' />
        </UnstyledButton>
      ) : null}
    </div>
  )
}

export default GsBadge
