import { useLocalizationSettings } from '@hooks/useLocalizationSettings'
import { LoadingOverlay, Select } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useI18nContext } from '@packages/i18n'
import { useCallback, useState } from 'react'

type LanguageSelectorProps = {
  readonly hideLabel?: boolean
}

const LanguageSelector = ({ hideLabel }: LanguageSelectorProps) => {
  const { currentLocale, updateLanguage } = useLocalizationSettings()
  const { setLocale, LL } = useI18nContext()
  const [isLoading, setIsLoading] = useState(false)

  const handleUpdateLanguage = useCallback(
    (newValue: string | null | undefined) => {
      if (!newValue) return

      setIsLoading(true)

      let newLocale: 'auto' | 'de' | 'en' = 'auto'

      if (newValue === 'en') newLocale = 'en'
      if (newValue === 'de') newLocale = 'de'

      updateLanguage(newLocale)
        .then((newLocaleValue) => {
          setLocale(newLocaleValue)
        })
        .catch(() => {
          notifications.show({
            color: 'red',
            message: 'Failed to update language',
          })
        })
        .finally(() => setIsLoading(false))
    },
    [setLocale, updateLanguage]
  )

  return (
    <div className='relative max-w-[200px]'>
      <LoadingOverlay
        className='no-delay'
        loaderProps={{
          size: 'sm',
        }}
        visible={isLoading}
      />
      <Select
        data={[
          { label: 'English', value: 'en' },
          { label: 'Deutsch', value: 'de' },
        ]}
        label={hideLabel ? undefined : LL.settingsPage.personal.language()}
        onChange={handleUpdateLanguage}
        value={currentLocale}
      />
    </div>
  )
}

export default LanguageSelector
