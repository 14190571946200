export const GsResources = ['Members', 'Settings', 'Aoa', 'Checkins'] as const
export const GsActions = ['Create', 'Read', 'Update', 'Delete'] as const
export const GsCustomPermissions = ['OwnedAoaContent:CRUD'] as const

type GsResource = (typeof GsResources)[number]

type GsAction = (typeof GsActions)[number]

type GsCustomPermission = (typeof GsCustomPermissions)[number]

export type GsPermission = `${GsResource}:${GsAction}` | GsCustomPermission
