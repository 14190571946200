import type { Handle } from '@web/router/Router'
import { useMatches } from 'react-router-dom'

const useRouteData = () => {
  const matches = useMatches() as Array<
    Omit<ReturnType<typeof useMatches>[number], 'handle'> & {
      handle?: Handle
    }
  >

  const lastMatch = matches.at(-1)

  return {
    lastMatch,
    matches,
    pageOptions: lastMatch?.handle?.pageOptions,
  }
}

export default useRouteData
