import type { BaseTranslation } from '../i18n-types'
import en_ai from './en_ai'
import en_aoa from './en_aoa'
import en_auth from './en_auth'
import en_emails from './en_emails'
import en_org from './en_org'
import en_settings from './en_settings'

const en = {
  ...en_emails,
  ...en_settings,
  ...en_aoa,
  ...en_auth,
  ...en_org,
  ...en_ai,
  approvalStatus: {
    approved: 'Approved',
    archived: 'Archived',
    draft: 'Draft',
    pending: 'Pending approval',
    withdrawn: 'Closed',
  },
  buttons: {
    add: 'Add',
    apply: 'Apply',
    approve: 'Approve',
    back: 'Back',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    continue: 'Continue',
    delete: 'Delete',
    deselectAll: 'Deselect all',
    details: 'Details',
    edit: 'Edit',
    filter: 'Filter',
    hidePreview: 'Hide preview',
    horizontalLayout: 'Horizontal layout',
    import: 'Import',
    random: 'Random',
    reloadPage: 'Reload page',
    remove: 'Remove',
    resetAll: 'Reset all',
    save: 'Save',
    selectAll: 'Select all',
    showPreview: 'Show preview',
    stopEdit: 'Stop editing',
    verticalLayout: 'Vertical layout',
    closeAoa: 'Close AOA',
    addActions: 'Add Multiple Actions',
    stopAddingActions: 'Stop adding actions',
    addOutcomes: 'Add Outcomes',
    stopAddingOutcomes: 'Stop adding outcomes',
    viewProfile: 'View Profile',
    viewTeam: 'View Team',
  },
  common: {
    and: 'and',
    removed: 'removed',
    areYouSure: 'Are you sure?',
    backToStart: 'Back to start',
    comment: 'Comment',
    copiedToClipboard: 'Copied to clipboard',
    createdAt: 'Created at',
    description: 'Description',
    fileUpload: {
      selectFile: 'Select file{{s}}',
      title: '<>Click to upload</> or drag and drop',
    },
    maybe: 'Maybe',
    no: 'No',
    noComments: 'No comments',
    noResults: 'No results',
    notFound: 'Not found',
    search: 'Search',
    searchDots: 'Search...',
    showLess: 'Show less',
    showMore: 'Show more',
    status: {
      active: 'Active',
      declined: 'Declined',
      expired: 'Expired',
      failed: 'Failed',
      high: 'High',
      inactive: 'Inactive',
      invited: 'Invited',
      joined: 'Joined',
      low: 'Low',
      medium: 'Medium',
      notStarted: 'Not started',
      pending: 'Pending',
      terminated: 'Terminated',
      unknown: 'Unknown',
    },
    updatedAt: 'Updated at€',
    xMore: '{0:number} more',
    yes: 'Yes',
  },
  datetime: {
    at: 'on {date:string} at {time:string}',
    createdAtDate: 'Created at {date:string}',
    lastActivity: 'Last activity {date:string}',
    timeline: 'Timeline',
  },
  error: {
    failedToLoad: 'Failed to load {0:string}.',
    fileTooLarge: "The file's size exceeds the maximum allowed size.",
    fileUploadFailed: 'Failed to upload file {0:string}',
    invalidFileType: 'The file has an unsupported file type.',
    unknownError: 'An unknown error occurred.',
  },
  errorPages: {
    common: {
      failedToLoadDescription:
        'Try reloading the page, or contact support if the problem persists.',
      notFoundDescription:
        'You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.',
    },
    orgNotFound: {
      button: 'Take me to the start page',
      description:
        'The organization you are looking for does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.',
      headline: 'Organization not found',
    },
    pageNotFound: {
      headline: 'Page not found',
      message:
        "The page you're looking for does not exist. Check if the URL was mistyped or if the page has been moved to another URL.",
    },
    profile: {
      profileFailedToLoad: 'Failed to load profile',
      profileNotFound: 'Profile not found',
    },
    team: {
      teamNotFound: 'Team not found',
    },
    unauthorized: {
      button: 'Take me to the start page',
      description:
        'You may not have the necessary permissions to view this section. If you think this is an error contact support.',
      headline: 'You are not authorized to view this section',
    },
  },
  filters: {
    dateRange: {
      endingAt: 'until {date:string}',
      exactDate: 'on {date:string}',
      pickDate: 'Pick a date',
      range: '{startDate:string} to {endDate:string}',
      startingAt: 'since {date:string}',
    },
  },
  homePage: {
    pageHeader: 'Execution Dashboard',
    pageSubheader: 'Welcome to GrowthSquare',
    quickLinks: {
      empty: 'Nothing here yet.',
      headline: 'Quick Links',
      subheader: 'Selected AOAs',
    },
  },
  inputs: {
    searchOrTypeToCreate: 'Search or type to create',
    units: {
      createCustomUnit: 'Use custom unit „{0:string}“',
      decimal: 'Decimal Number',
      dollar: 'Dollar',
      euro: 'Euro',
      number: 'Number',
      percentage: 'Percentage',
      selectBoxLabel: 'Unit',
    },
  },
  navigation: {
    settings: {
      methodology: 'Methodology',
      notifications: 'Notifications',
      organization: 'Organization',
      personal: 'Account',
      profile: 'My Profile',
      team: 'Team',
    },
    sidebar: {
      ai: {
        label: 'Strategy AI',
      },
      aoa: {
        label: 'AOA',
        subItems: {
          backToOverview: 'Back to all AOAs',
          checkin: 'Status Check-In',
          filterPage: 'Dashboard',
          heatmap: 'Heatmap',
          overview: 'Overview',
        },
      },
      home: 'Home',
      organization: {
        label: 'Organization',
        subItems: {
          backToOverview: 'Back to all Members',
          members: 'Members',
          overview: 'Overview',
        },
      },
      settings: 'Settings',
    },
  },
  notifications: {
    error: 'Something went wrong',
    success: 'Success',
    successMessage: 'Sucessfully created {0:string}',
  },
  table: {
    next: 'Next',
    of: 'of',
    page: 'Page',
    previous: 'Previous',
  },
  tags: {
    'gs:highPriority': 'High Priority',
    'user:you': 'You',
  },
  validation: {
    emailInvalid: 'Please enter a valid email address.',
    isRequired: '{0:string} is required.',
    onlyLowercaseLettersNumbersAndDashes:
      'Only lowercase letters, numbers and dashes are allowed.',
    passwordMinLength: 'The password must be at least {0:number} characters.',
    passwordsMustMatch: 'The passwords must match.',
    tooLong: 'The value must be at most {0:number} characters long.',
    tooShort: 'The value must be at least {0:number} characters long.',
    valueCantStartWith: 'The value cannot start with {0:string}.',
  },
} satisfies BaseTranslation

export default en
