import { z } from 'zod'

const emailTypes = [
  'CHECKIN_REMINDER',
  'NEW_INVITATION',
  'CustomEmailSender_SignUp',
  'CustomEmailSender_ResendCode',
  'CustomEmailSender_ForgotPassword',
  'CustomEmailSender_UpdateUserAttribute',
  'CustomEmailSender_VerifyUserAttribute',
  'CustomEmailSender_AdminCreateUser',
  'CustomEmailSender_AccountTakeOverNotification',
] as const

export const emailEntry = z.object({
  attempts: z.array(
    z.object({
      emailProvider: z.string(),
      errorMessage: z.string().optional(),
      externalId: z.string().optional(),
      statusCode: z.number().optional(),
      success: z.boolean(),
      timestamp: z.string().datetime(),
    })
  ),
  email: z.string().email(),
  emailType: z.enum(emailTypes),
  idempotencyId: z.string(),
  success: z.boolean(),
  tenantId: z.string(),
})

export type EmailEntry = Omit<z.infer<typeof emailEntry>, 'tenantId'> & {
  tenantId: 'UNRELATED' | (string & {})
}
