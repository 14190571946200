import TenantListItem from '@components/auth/TenantListItem'
import { MenuButton } from '@components/core/action-buttons'
import { openAcceptInvitationModal } from '@components/organization/tenant'
import { useAuth } from '@hooks'
import { Button, Divider, Menu, Text, UnstyledButton } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import { IconLogin2, IconLogout, IconPlus } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { openAddAccountModal } from '.'

const AccountMenu = ({
  username,
  authenticated,
  allowCreateTenant,
}: {
  readonly allowCreateTenant?: boolean
  readonly authenticated?: boolean
  readonly username: string
}) => {
  const { signOut, switchUser } = useAuth<true>()
  const { LL } = useI18nContext()
  const navigate = useNavigate()

  return (
    <Menu position='bottom' shadow='sm' width={200}>
      <Menu.Target>
        <MenuButton color='gray' size={18} />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          disabled={!(authenticated && allowCreateTenant)}
          leftSection={<IconPlus size={14} />}
          onClick={() => {
            void switchUser({ navigate, navigateTo: '/onboarding', username })
          }}
        >
          {LL.auth.createOrganization()}
        </Menu.Item>
        <Menu.Item
          leftSection={<IconLogout size={14} />}
          onClick={() => {
            void signOut(username)
          }}
        >
          {LL.auth.signOut()}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const UserTenantList = ({
  onSwitched,
  menuHidden,
  navigateTo,
}: {
  readonly onSwitched?: (username: string, tenantId: string) => void
  readonly navigateTo?: string
  readonly menuHidden?: boolean
}) => {
  const {
    switchUser,
    user,
    tenantId,
    ssoSignIn,
    sessions,
    sessionRestoreFinished,
    restoreSessions,
  } = useAuth()

  const navigate = useNavigate()

  const { LL } = useI18nContext()

  const [loadingWorkspace, setLoadingWorkspace] = useState<
    | {
        tenantId: string
        username: string
      }
    | undefined
  >()

  const handleSelect = useCallback(
    async (username: string, newTenantId: string) => {
      if (loadingWorkspace) return

      setLoadingWorkspace({
        tenantId: newTenantId,
        username,
      })
      await switchUser({
        navigate,
        tenantId: newTenantId,
        username,
        navigateTo,
      }).finally(() => setLoadingWorkspace(undefined))

      onSwitched?.(username, newTenantId)
    },
    [loadingWorkspace, navigateTo, navigate, onSwitched, switchUser]
  )

  return (
    <div className='w-full'>
      {Object.entries(sessions).map(
        ([
          username,
          {
            providerName,
            userAttributes: { email },
            latestAccountInfo: { teams, account, invitations },
            authenticated,
            meta,
          },
        ]) => (
          <div key={username}>
            <Menu.Label className='mt-4 !pb-0'>
              <div className='flex w-full gap-x-1'>
                <div className='flex-1 truncate'>{email}</div>
                {menuHidden ? null : (
                  <div className='ml-auto size-4'>
                    <AccountMenu
                      allowCreateTenant={meta?.allowCreateTenant}
                      authenticated={authenticated}
                      username={username}
                    />
                  </div>
                )}
              </div>
            </Menu.Label>
            {!authenticated && sessionRestoreFinished && (
              <div className='px-3'>
                <Text c='red' fw='500' size='sm'>
                  {LL.auth.sessionExpired()}
                </Text>
                <Button
                  color='red'
                  fullWidth
                  mb='xs'
                  mt={4}
                  onClick={() => {
                    if (authenticated) {
                      void restoreSessions()
                    } else if (providerName) {
                      const tenantWithProvider = teams.find((team) =>
                        team.tenant.authSettings.identityProviders.some(
                          (identityProvider) =>
                            identityProvider.providerName === providerName
                        )
                      )

                      if (tenantWithProvider)
                        void ssoSignIn(
                          providerName,
                          tenantWithProvider.tenant.authSettings
                        )
                    } else
                      openAddAccountModal({
                        username: email,
                      })
                  }}
                  rightSection={<IconLogin2 size={18} />}
                  variant='light'
                >
                  <span className='whitespace-normal text-sm'>
                    {LL.auth.sessionExpiredCta()}
                  </span>
                </Button>
              </div>
            )}
            {teams.map((team) => (
              <UnstyledButton
                className='w-full'
                disabled={Boolean(loadingWorkspace) || !authenticated}
                key={team.tenantId}
                onClick={() => {
                  if (authenticated) {
                    void handleSelect(username, team.tenantId)
                  }
                }}
              >
                <TenantListItem
                  disabled={!authenticated}
                  isLoading={
                    loadingWorkspace?.username === username &&
                    loadingWorkspace?.tenantId === team.tenantId
                  }
                  isSelected={
                    !loadingWorkspace &&
                    user?.username === username &&
                    tenantId === team.tenantId &&
                    authenticated
                  }
                  subText={[
                    team.planTitle,
                    LL.organization.memberCount(team.memberCount),
                  ]
                    .filter(Boolean)
                    .join('・')}
                  tenantInfo={team.tenant}
                />
              </UnstyledButton>
            ))}

            {sessionRestoreFinished &&
              authenticated &&
              account &&
              !teams.length &&
              !invitations.length && (
                <div className='mt-2 pl-3'>
                  <Text c='gray.6' fw='500' mb={6} mt={-6} size='sm'>
                    {LL.auth.noOrgs()}
                  </Text>
                  <div className='mb-3 grid grid-cols-[auto_1fr] place-items-center gap-2'>
                    <div className='w-full'>
                      <Button
                        onClick={async () =>
                          await switchUser({
                            navigate,
                            navigateTo: '/onboarding',
                            username,
                          })
                        }
                        rightSection={<IconPlus size={20} />}
                        size='xs'
                        variant='default'
                      >
                        {LL.auth.createOrganization()}
                      </Button>
                    </div>
                  </div>
                </div>
              )}

            {invitations && invitations.length > 0 && (
              <Text fw='600' mt={0} px='xs' size='sm'>
                {LL.auth.invitedToOrgsList()}
              </Text>
            )}
            {invitations.map((invitation) => (
              <UnstyledButton
                className='w-full'
                disabled={Boolean(loadingWorkspace) || !authenticated}
                key={invitation.invitation.tenantId}
                onClick={async () => {
                  await switchUser({ navigate, username })

                  openAcceptInvitationModal({
                    heading: LL.auth.acceptInvitation(),
                    invitationResponse: invitation,
                    navigate,
                    username: account.cognitoUsername,
                  })
                }}
              >
                <TenantListItem
                  subText={[
                    invitation.planTitle,
                    LL.organization.memberCount(invitation.memberCount),
                  ]
                    .filter(Boolean)
                    .join('・')}
                  tenantInfo={invitation.tenant}
                />
              </UnstyledButton>
            ))}
            <Divider color='gray.2' />
          </div>
        )
      )}
    </div>
  )
}

export default UserTenantList
