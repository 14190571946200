import { notifications } from '@mantine/notifications'
import type { ApiReturnTypes, TenantLoginInfo } from '@types'
import { tenantInfoShape } from '@utils/localstorage-utils'
import { trpc } from '@utils/trpc'
import { useCallback, useEffect, useState } from 'react'

export const useProvideTenant = () => {
  const [cachedDefaultAuthInfo, setCachedDefaultAuthInfo] = useState<
    ApiReturnTypes['public-router']['getDefaultAuthInfo'] | undefined
  >()

  const utils = trpc.useUtils()

  const defaultAuthInfo =
    trpc['public-router'].getDefaultAuthInfo.useQuery(undefined, {
      refetchOnWindowFocus: false,
      // 24 hours
      staleTime: 1_000 * 60 * 60 * 24,
    }).data ?? cachedDefaultAuthInfo

  useEffect(() => {
    if (defaultAuthInfo) setCachedDefaultAuthInfo(defaultAuthInfo)
  }, [defaultAuthInfo])

  const getTenantInfoForAlias = useCallback(
    async (alias: string): Promise<TenantLoginInfo | false | undefined> => {
      const tenantInfo = await utils['public-router'].getAuthInfoByTenantAlias
        .fetch(alias)
        .catch(() => undefined)

      if (!tenantInfo) return undefined

      const result = tenantInfoShape.safeParse(tenantInfo)

      if (!result.success) {
        notifications.show({
          color: 'red',
          message:
            'Your organization is not configured correctly. Please contact support.',

          title: 'Error',
        })
        return false
      }

      return result.data
    },
    [utils]
  )

  return {
    defaultAuthInfo:
      defaultAuthInfo as ApiReturnTypes['public-router']['getDefaultAuthInfo'],
    getTenantInfoForAlias,
  }
}
