import { LoadingOverlay } from '@mantine/core'
import { getResolvedDomains } from '@packages/constants'
import TenantNotFoundPage from '@pages/error/TenantNotFoundPage'
import { isTRPCClientError, trpc } from '@utils/trpc'

type DomainPrefixRedirectPageProps = {
  readonly hostname: string
}

const DomainPrefixRedirectPage = ({
  hostname,
}: DomainPrefixRedirectPageProps) => {
  const { data, isPending, error } = trpc[
    'public-router'
  ].getTenantIdByHostname.useQuery(hostname, {
    enabled: Boolean(hostname),
    retry: 1,
  })

  const { webRecord } = getResolvedDomains(window.location.hostname)

  if (isPending) return <LoadingOverlay id='alias.loading' visible />

  if (data) {
    // navigate to https://${webRecord}/?org=${data}
    const path = window.location.pathname
    window.location.href = `https://${webRecord}${path}?tenantId=${encodeURIComponent(
      data
    )}`
    return null
  }

  if (isTRPCClientError(error) && error.data?.code === 'NOT_FOUND') {
    return <TenantNotFoundPage />
  }
  // TODO: Handle other errors

  window.location.href = `https://${webRecord}`

  return null
}

export default DomainPrefixRedirectPage
