import GsBadge from '@components/core/GsBadge'
import ErrorState from '@components/core/faulty-states/ErrorState'
import UnauthorizedState from '@components/core/faulty-states/UnauthorizedState'
import PageHeader from '@components/typography/PageHeader'
import { useReload } from '@hooks'
import { Button, Code, Spoiler, Text, Title } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import { cn } from '@utils'
import {
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from 'react-router-dom'

type ErrorFallbackProps = {
  readonly customError?: {
    status: number
  }
  readonly root?: boolean
}

const getErrorInfo = (error: unknown & {}, LL: TranslationFunctions) => {
  const statusCode =
    'status' in error && typeof error.status === 'number'
      ? error.status
      : undefined

  const isCriticalError = statusCode && statusCode >= 500 && statusCode < 600

  let statusText =
    'statusText' in error && typeof error.statusText === 'string'
      ? error.statusText
      : 'error'

  if (statusText.length > 50) statusText = `${statusText.slice(0, 32)}...`

  let reason =
    'message' in error && typeof error.message === 'string'
      ? error.message
      : 'data' in error && typeof error.data === 'string'
        ? error.data
        : undefined

  let isReloadAvailable = true

  let pageTitle = LL.error.unknownError()

  if (reason?.startsWith('Error: No route matches')) {
    reason = LL.errorPages.pageNotFound.message()
    isReloadAvailable = false
    pageTitle = LL.errorPages.pageNotFound.headline()
  }

  const errorString =
    error instanceof Error ? error.toString() : JSON.stringify(error, null, 2)

  const rawErrorStack =
    error instanceof Error
      ? error.stack?.replaceAll(`${window.location.origin}/`, '')
      : undefined

  const errorStackLines: (string | undefined)[] = []

  for (const line of rawErrorStack?.split('\n') ?? []) {
    if (line.includes('/chunk-')) {
      errorStackLines.push(line.split('(').at(0))
    } else if (line.includes('?v=') || line.includes('?t=')) {
      const delimiter = line.includes('?v=') ? '?v=' : '?t='
      // remove timestamp from stack trace
      const [path, ...rest] = line.split(delimiter)
      const end = rest.join('').split(':').slice(1).join(':')
      errorStackLines.push(`${path}:${end}`)
      // check if lines ends with chunk-<hash>.js
    } else {
      errorStackLines.push(line)
    }
  }

  const errorStack = errorStackLines
    .filter(Boolean)
    .map((line) => line.replaceAll('(<anonymous>)', '').trimEnd())
    .join('\n')

  return {
    errorString,
    isCriticalError,
    isReloadAvailable,
    pageTitle,
    reason,
    statusCode,
    statusText,
    errorStack,
  }
}

const UnexpectedErrorComponent = ({
  error,
  root,
}: {
  readonly error: unknown
  readonly root?: boolean
}) => {
  const location = useLocation()
  const reload = useReload()
  const { LL } = useI18nContext()

  if (!error || typeof error !== 'object') return null

  const {
    errorString,
    isCriticalError,
    isReloadAvailable,
    pageTitle,
    reason,
    statusCode,
    statusText,
    errorStack,
  } = getErrorInfo(error, LL)

  return (
    <div
      className={cn('mt-12 md:grid md:place-items-center', {
        'px-4 py-2 sm:px-6 sm:py-6 lg:px-8': root,
      })}
    >
      <div className='bg-white pt-8 pb-24 px-6 rounded-xl mx-auto max-w-max'>
        <main>
          <div>
            <div>
              <div className='space-y-4'>
                {statusCode && (
                  <GsBadge
                    color={isCriticalError ? 'red' : 'yellow'}
                    size='lg'
                    variant='modern'
                    withDot
                    className='w-fit'
                  >
                    {statusCode} {statusText}
                  </GsBadge>
                )}
                <Title order={2}>{pageTitle}</Title>
                {reason && (
                  <Text c='gray.6' fw='400' size='xl'>
                    {reason}
                  </Text>
                )}
              </div>
              <Spoiler
                className='mt-12'
                hideLabel={LL.common.showLess()}
                maxHeight={250}
                showLabel={LL.common.showMore()}
              >
                {/* <pre>
                  {JSON.stringify(
                    { x: Object.keys((error as Error).cause) },
                    null,
                    2
                  )}
                </pre> */}
                <div className='space-y-4'>
                  <Code className='!bg-white'>Path: {location.pathname}</Code>
                  <Code block className='max-w-3xl !bg-gray-50'>
                    {import.meta.env.DEV ? errorStack : errorString}
                  </Code>
                </div>
              </Spoiler>
            </div>
            <div className='mt-10 flex space-x-3'>
              <Button
                component='a'
                onClick={() => {
                  reload('/')
                }}
                variant={isReloadAvailable ? 'light' : 'filled'}
              >
                {LL.common.backToStart()}
              </Button>
              {isReloadAvailable && (
                <Button
                  component='a'
                  onClick={() => {
                    reload()
                  }}
                  variant='default'
                >
                  {LL.buttons.reloadPage()}
                </Button>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

const ErrorFallback = (props: ErrorFallbackProps) => {
  const error = useRouteError() ?? props.customError

  if (isRouteErrorResponse(error) && error.status === 403) {
    return (
      <>
        <PageHeader headline='' withoutDivider />
        <UnauthorizedState />
      </>
    )
  }

  if (
    isRouteErrorResponse(error) &&
    error.data.manuallyThrown === true &&
    !props.root
  ) {
    return (
      <div>
        <PageHeader headline='' withoutDivider />
        <ErrorState
          headline={error.data?.message ?? error.statusText}
          isNotFoundError
        />
      </div>
    )
  }

  return <UnexpectedErrorComponent error={error} root={props.root} />
}

export default ErrorFallback
