import gsLogoUrl from '@assets/logos/gs-logo-inverse-boxed.svg?url'
import { cn } from '@utils'

const BrandLogo = (props: {
  readonly className?: string
  readonly fullClassName?: string
  readonly iconClassName?: string
  readonly type: 'full' | 'icon'
}) => {
  return (
    <div className='h-9 transition-opacity duration-200'>
      <div
        className={cn(
          'absolute duration-200',
          'text-white',
          {
            'opacity-0': props.type === 'full',
            'opacity-100 delay-100': props.type === 'icon',
          },
          props.className,
          props.iconClassName
        )}
      >
        <svg
          fill='none'
          height={32}
          width={32}
          xmlns='http://www.w3.org/2000/svg'
        >
          <title>GrowthSquare</title>
          <path
            d='M0 12.8c0-4.48 0-6.72.872-8.432A8 8 0 0 1 4.368.872C6.08 0 8.32 0 12.8 0h6.4c4.48 0 6.72 0 8.432.872a8 8 0 0 1 3.496 3.496C32 6.08 32 8.32 32 12.8v6.4c0 4.48 0 6.72-.872 8.432a8 8 0 0 1-3.496 3.496C25.92 32 23.68 32 19.2 32h-6.4c-4.48 0-6.72 0-8.432-.872a8 8 0 0 1-3.496-3.496C0 25.92 0 23.68 0 19.2v-6.4Z'
            fill='#FFC107'
          />
          <path
            d='M8.5 20.906 10.524 26l3.389-8.355 3.56 8.35L24.224 6'
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.6}
          />
        </svg>
      </div>

      <img
        alt='Growthsquare'
        className={cn(
          'w-full duration-200',
          {
            'opacity-0': props.type === 'icon',
            'opacity-100 delay-100': props.type === 'full',
          },
          props.className,
          props.fullClassName
        )}
        src={gsLogoUrl}
      />
    </div>
  )
}

export default BrandLogo
