import { setContext, setTag, setUser } from '@sentry/react'
import { useMemo } from 'react'
import { useAuth } from './useAuth'

export const useSentryContext = () => {
  const { tenant, tenantId, userAttributes } = useAuth()

  const userContext = useMemo(
    () =>
      userAttributes.sub
        ? ({
            ...userAttributes,
            id: userAttributes.sub,
            identities: undefined,
            ip_address: '{{auto}}',
          } as const)
        : null,
    [userAttributes]
  )

  const tenantContext = useMemo(() => {
    if (!tenant) return null
    return {
      ...tenant,
      displayName: tenant.tenant.displayName,
      tenant: undefined,
    } as const
  }, [tenant])

  setTag('tenantId', tenantId)
  setTag('tenantAlias', tenant?.tenant.alias)
  setContext('tenant', tenantContext)
  setUser(userContext)
}
