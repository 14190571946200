import { Button, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { translateCognitoError } from '@utils/error-utils'
import {
  AuthError,
  type ResendSignUpCodeOutput,
  resendSignUpCode,
  resetPassword,
} from 'aws-amplify/auth'
import { useCallback, useState } from 'react'
import { z } from 'zod'

type ResetPasswordFormProps = {
  readonly initialEmail?: string
  readonly onComplete: () => void
  readonly onConfirmEmail: (
    props: ResendSignUpCodeOutput,
    email: string
  ) => void
}

const loginSchema = z.object({
  email: z.string().email('A valid email is required'),
})

const ResetPasswordForm = ({
  onComplete,
  initialEmail,
  onConfirmEmail,
}: ResetPasswordFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { LL } = useI18nContext()

  const form = useForm({
    initialValues: {
      confirmPassword: '',
      email: initialEmail ?? '',
      password: '',
    },
    validate: zodResolver(loginSchema),
  })

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      setIsLoading(true)
      try {
        await resetPassword({ username: email })
        onComplete()
      } catch (error) {
        if (
          error instanceof AuthError &&
          error.name === 'InvalidParameterException' &&
          error.message.includes('no registered/verified email or phone_number')
        ) {
          await resendSignUpCode({
            username: email,
          })
            .then((result) => {
              onConfirmEmail(result, email)
            })
            .catch((anotherError) => {
              setErrorMessage(translateCognitoError(LL, anotherError))
            })
        }

        setErrorMessage(translateCognitoError(LL, error))
      } finally {
        setIsLoading(false)
      }
    },
    [LL, onComplete, onConfirmEmail]
  )

  return (
    <form className='relative' onSubmit={form.onSubmit(handleSubmit)}>
      <TextInput
        disabled={isLoading}
        label={LL.auth.emailLabel()}
        mt='md'
        size='md'
        type='email'
        variant='default'
        {...form.getInputProps('email')}
        autoComplete='email'
        mb='md'
      />
      <Text c='red' className='min-h-[30px]' fw='600' mt='sm' size='md'>
        {errorMessage}
      </Text>
      <Button fullWidth loading={isLoading} size='md' type='submit'>
        {LL.auth.resetPassword()}
      </Button>
    </form>
  )
}

export default ResetPasswordForm
