import type { Translation } from '../i18n-types'

const de_ai = {
  ai: {
    welcomeTitle: 'Willkommen zur GrowthSquare Strategie AI',
    welcomeDescription:
      'Ich bin hier, um Ihnen bei Fragen zur Strategie Ihrer Organisation zu helfen.',
    sources: 'Quellen',
    responseDetails: {
      menuLabel: 'Informationen',
      tokenUsage: 'Verbrauchte Eingabe-/Ausgabetokens',
      responseDuration: 'Antwortdauer',
      responseError: 'Fehler',
    },
    startNewConversation: 'Neuer Chat',
  },
} satisfies Pick<Translation, 'ai'>

export default de_ai
