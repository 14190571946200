import { trpc } from '@utils/trpc'

type QueryOptions = {
  enabled?: boolean
  staleTime?: number
}

export const aoaApi = {
  useGetAoaDetails: (aoaId: string | undefined, options?: QueryOptions) => {
    // const context = trpc.useUtils()

    const query = trpc.aoa.getAoaDetails.useQuery(aoaId ?? '', {
      enabled: Boolean(aoaId),
      ...options,
      // TODO: make types compatible with this:
      // placeholderData: () => {
      //   return context.aoa.getAoaList
      //     .getData()
      //     ?.find((aoa) => aoa.aoaId === aoaId)
      // },
    })

    return query
  },

  useGetAoaList: (options?: QueryOptions) => {
    const query = trpc.aoa.getAoaList.useQuery(undefined, {
      staleTime: 1000 * 5,
      ...options,
    })

    return query
  },

  useGetResponsibleAoaItems: (options?: QueryOptions) => {
    const query = trpc.aoa.getResponsibleAoaItems.useQuery(
      {},
      {
        ...options,
      }
    )

    return query
  },
}
