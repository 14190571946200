import clsx, { type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export * from './aoa-utils'
export * from './formatting-utils'
export * from './i18n-custom-utils'
export * from './user-utils'

export const sleep = async (ms: number) =>
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  })

export const safeWindow =
  typeof window !== 'undefined' && !import.meta.env.SSR ? window : undefined

/**
 * Tailwind CSS classnames with clsx
 */
export const cn = (...classes: ClassValue[]) => twMerge(clsx(...classes))
export const cx = (...classes: ClassValue[]) => clsx(...classes)
