import { openAddAccountModal } from '@components/auth'
import TenantListItem from '@components/auth/TenantListItem'
import UserTenantList from '@components/auth/UserTenantList'
import { useAuth, useConfirm } from '@hooks'
import { Divider, Menu, ScrollArea, UnstyledButton } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import {
  IconAlertTriangle,
  IconLogout,
  IconSettings,
  IconUserPlus,
} from '@tabler/icons-react'
import { sleep } from '@utils'
import { useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { NavLink, useSearchParams } from 'react-router-dom'

type SidebarAccountMenuProps = {
  readonly isCollapsed?: boolean
}

const SidebarAccountMenu = ({ isCollapsed }: SidebarAccountMenuProps) => {
  const { userAttributes, signOutAll, tenant, clearTenant, sessions } =
    useAuth()
  const { LL } = useI18nContext()

  const [opened, setOpened] = useState(false)

  const [, setParameters] = useSearchParams()

  const handleSignOutAll = useConfirm({
    onConfirm: signOutAll,
    title: {
      heading: LL.auth.confirmSignOutAll(),
      icon: IconAlertTriangle,
      variant: 'danger',
    },
  })

  useEffect(() => {
    if (!tenant && window.location.search.includes('org=')) {
      openAddAccountModal()
    }
  }, [tenant])

  // sidebar-open
  const width = 256 - 2 * 2 * 4

  return (
    <Menu
      classNames={{
        divider: '!mb-0 !p-0',
        dropdown: '!p-0 !left-2',
        item: 'bg-gray-50 hover:!bg-gray-100 !py-sm !m-0',
        label: 'bg-white text-sm text-gray-500 font-semibold mt-xs',
      }}
      offset={0}
      onChange={setOpened}
      opened={opened}
      shadow='md'
      width={width}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton aria-label='current-tenant' className='flex w-full'>
          {tenant?.tenant && (
            <TenantListItem
              dark
              isCollapsed={isCollapsed}
              px='16px'
              py='16px'
              subText={userAttributes.email}
              tenantInfo={tenant.tenant}
              // withSelector
            />
          )}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <ScrollArea
          classNames={{
            viewport: 'max-h-[calc(100vh_-_200px)]',
          }}
          scrollbarSize={8}
          w='100%'
        >
          <div
            style={{
              width: width - 2,
            }}
          >
            <UserTenantList
              onSwitched={() => {
                setOpened(false)
              }}
            />
          </div>
        </ScrollArea>
        <Divider />
        <NavLink className='no-underline' to='/settings/personal'>
          <Menu.Item leftSection={<IconSettings size={14} />}>
            {LL.navigation.sidebar.settings()}
          </Menu.Item>
        </NavLink>
        <Menu.Item
          leftSection={<IconUserPlus size={14} />}
          onClick={async () => {
            flushSync(() => {
              setParameters({ pagestate: 'add-account' })
            })
            await sleep(50)
            clearTenant()
          }}
        >
          {LL.auth.addAccount()}
        </Menu.Item>
        <Menu.Item
          leftSection={<IconLogout size={14} />}
          onClick={() => handleSignOutAll()}
        >
          {Object.values(sessions).length > 1
            ? LL.auth.signOutAll()
            : LL.auth.signOut()}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default SidebarAccountMenu
