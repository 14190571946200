import type { Translation } from '../i18n-types'

const de_auth = {
  auth: {
    acceptInvitation: 'Einladung annehmen',
    addAccount: 'Ein weiteres Konto hinzufügen',
    backToHome: 'Zurück zur Startseite',
    changePasswordButton: 'Passwort ändern',
    checkInbox: 'Überprüfe dein E-Mail-Postfach',
    checkInboxDescription:
      'Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Passworts gesendet.',
    confirmAccount: 'Bestätige dein Konto',
    confirmationCodeHasBeenSentTo:
      'Ein Bestätigungscode wurde an <></> gesendet.',
    confirmPasswordLabel: 'Passwort bestätigen',
    confirmSignOutAll:
      'Bist du sicher, dass du dich von allen Konten abmelden möchtest?',
    createAccount: 'Konto erstellen',
    createAccountSubtitle: 'Bitte geben Sie Ihre Daten ein, um loszulegen.',
    createOrganization: 'Eine neue Organisation erstellen',
    declineInvitation: 'Einladung ablehnen',
    didNotReceiveCode: 'Keinen Code erhalten?',
    emailLabel: 'E-Mail',
    enterpriseLogin: 'Enterprise Login',
    errors: {
      aliasNotFound:
        'Wir konnten keine Organisation mit dieser Kennung finden. Bitte versuche es erneut.',
      CodeMismatchException:
        'Ungültiger Code. Er ist möglicherweise abgelaufen. Bitte versuche es erneut.',

      codeResendFailed: 'Fehler beim erneuten Senden des Codes',
      emailNotConfirmedError:
        'Leider können wir Ihr Passwort nicht zurücksetzen. Bitte kontaktieren Sie den Support.',
      InvalidParameterException: 'Ungültige Werte angegeben.',
      LimitExceededException:
        'Zu viele Versuche. Bitte versuche es später erneut.',
      NotAuthorizedException: {
        unauthorized: 'Falscher Benutzername oder Passwort.',
        userAlreadyConfirmed:
          'Benutzer ist bereits bestätigt. Bitte melde dich an.',
      },

      PasswordResetRequiredException:
        'Du musst dein Passwort zurücksetzen um fortzufahren.',
      passwordsDoNotMatch: 'Passwörter stimmen nicht überein.',
      UsernameExistsException:
        'Ein Konto mit dieser E-Mail-Adresse existiert bereits.',
      UserNotFoundException: 'Kein Konto mit dieser E-Mail-Adresse existiert.',
    },
    forgotPassword: 'Passwort vergessen?',
    invalidInvitation: 'Ungültige Einladung',
    invalidInvitationDescription:
      'Die Einladung ist ungültig oder ist abgelaufen.',
    invitationAsRoleSuffix: 'als {role}',
    invitationMessage: 'Du wurdest eingeladen <>{org}</> beizutreten',
    invitationMessageBySomeone1: 'Du wurdest von <>{name}</> eingeladen, ',
    invitationMessageBySomeone2: '{org} beizutreten',
    invitationSignIn: 'Anmelden, um loszulegen',
    invitationSignUp: 'Registrieren, um loszulegen',
    invitationWelcomeText:
      'Du wurdest eingeladen, der Organisation <>{0}</> beizutreten.',
    invitedToOrgsList: 'Du wurdest zu folgenden Organisationen eingeladen:',
    loginUnavailable:
      'Login ist für diese Organisation derzeit nicht verfügbar.',
    newPasswordRequired: 'Neues Passwort erforderlich',
    noOrgs: 'Keine Organisationen gefunden.',
    noUsersFound: 'Keine Benutzer gefunden.',
    onboarding: {
      headline: 'Willkommen bei GrowthSquare',
      headlineDescription: 'Wir benötigen noch ein paar Informationen von dir.',
    },
    passwordLabel: 'Passwort',
    pickNewPassword: 'Wähle ein neues Passwort',
    placeholders: {
      confirmPassword: 'Passwort wiederholen',
      createPassword: 'Neues Passwort',
      email: 'Ihre E-Mail-Adresse',
      password: '••••••••',
    },
    resend: 'Erneut senden',
    resendCooldownMessage: 'Erneut senden in {0} Sekunde{{n}}',
    resetPassword: 'Passwort zurücksetzen',
    resetPasswordDescription:
      'Gib deine E-Mail-Adresse ein und wir senden dir einen Link zum Zurücksetzen deines Passworts.',
    selectIteration: 'Iteration auswählen…',
    selectOrganization: 'Wähle eine Organisation aus, um fortzufahren',
    selectTeam: 'Team auswählen…',
    selectUser: 'Benutzer auswählen…',
    sessionExpired: 'Deine Sitzung ist abgelaufen. Bitte melde dich erneut an.',
    sessionExpiredCta: 'Erneut anmelden',
    signIn: 'Anmelden',
    signInCta: 'Bereits registriert? <>Anmelden</>',
    signInSubtitle: 'Willkommen zurück! Bitte geben Sie Ihre Daten ein.',
    signInWithSso: 'Mit SSO anmelden',
    signOut: 'Abmelden',
    signOutAll: 'Von allen Konten abmelden',
    signUp: 'Registrieren',
    signUpCta: 'Noch kein Konto? <>Registrieren</>',
    switchOrganization: 'Organisation wechseln',
    tenantAliasLabel: 'Organisationskennung',
    unknownUser: 'Unbekannter Benutzer',
    switchToSso: 'Single Sign-On (SSO) verwenden',
    validation: {
      invalidEmail: 'Ungültige E-Mail-Adresse.',
      passwordLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    },
  },
} satisfies Pick<Translation, 'auth'>

export default de_auth
