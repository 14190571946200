import type { BaseTranslation } from 'typesafe-i18n'

const en_emails = {
  emails: {
    checkinNotification: {
      button: 'Check in now',
      headline: "Hey, it's time to check in!",
      intro:
        "{organizationName:string} is still awaiting your regular check-in. Please take a moment to set the status of your items and let us know how you're doing.",
      previewText: 'It is time to check in for {organizationName:string}',
      status: {
        allMissing: 'You have {missing:number} missing check-ins.',
        completed: 'You have already checked in for this week.',
        nothingToCheckIn: 'You have nothing to check in for this week.',
        partiallyCompleted:
          'You have {missing:number} out of {total:number} check-ins still open.',
      },
      subject: 'Check-In Reminder for {organizationName:string}',
    },
    common: {
      code: 'code',
      contact: {
        intro: 'Questions or concerns? Get in touch with us at',
        manageEmailPreferences: 'Manage your email preferences',
        noMoreMails: "Don't want any more emails like this?",
        or: 'or',
      },
      expirationDisclaimer:
        "Please note that this {type:string} is only valid for {validity:number} hour{{validity:s}}. If you don't confirm your email address within this time frame, you'll need to request a new {type:string}.",
      fallbackLinkDisclaimer:
        "If you're having trouble clicking the button, copy and paste the URL below:",
      greeting: 'Hi {0:string},',
      greetingAnonymous: 'Hi there,',
      link: 'link',
    },
    confirmationCode: {
      headline: 'Confirm your email address',
      intro:
        'We received a request to confirm your email address for your GrowthSquare account. Please use the following confirmation code to confirm your email address:',
      previewText: 'Your confirmation code is {code:string}',
      subject: 'Your confirmation code',
    },
    forgotPassword: {
      button: 'Reset password',
      headline: 'Reset your password',
      intro:
        'We received a request to reset your password. If you did not make this request, please ignore this email. Otherwise, you can reset your password by clicking the button below.',
      previewText: 'Your password reset link for GrowthSquare',
      subject: 'Your password reset',
    },
    newInvitation: {
      button: 'Accept invitation',
      headline: 'You were invited!',
      intro:
        'You have been invited to join the GrowthSquare organization {organizationName:string}, click the button below to get started.',
      previewText: 'You have been invited to a GrowthSquare organization',
      subject: 'You have been invited to an organization',
    },
  },
} as const satisfies BaseTranslation

export default en_emails
