import { Anchor, Button, PasswordInput, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { IconIdBadge2 } from '@tabler/icons-react'
import type { DefaultAuthInfo } from '@types'
import { ReactTranslation } from '@utils'
import { useMemo } from 'react'
import { z } from 'zod'

type LoginFormProps = {
  readonly authInfo: DefaultAuthInfo
  readonly error?: string
  readonly forcedUsername?: string
  readonly isLoading: boolean
  readonly onForgotPassword: (email: string) => void
  readonly onSSOLogin: (providerName: string) => void
  readonly onSignUp: () => void
  readonly onSubmit: (args: { password: string; username: string }) => void
}

const LoginForm = ({
  authInfo,
  onSubmit,
  isLoading,
  onSSOLogin,
  error,
  forcedUsername,
  onSignUp,
  onForgotPassword,
}: LoginFormProps) => {
  const { LL } = useI18nContext()

  const loginSchema = useMemo(
    () =>
      z.object({
        password: z
          .string()
          .min(8, { message: LL.validation.passwordMinLength(8) }),
        username: z.string().email(LL.auth.validation.invalidEmail()),
      }),
    [LL]
  )

  const initialCredentials = useMemo(() => {
    const query = new URLSearchParams(window.location.search)
    return {
      password: query.get('password'),
      username: query.get('username'),
    }
  }, [])

  const form = useForm({
    initialValues: {
      password: initialCredentials.password ?? '',
      username: forcedUsername ?? initialCredentials.username ?? '',
    },
    validate: zodResolver(loginSchema),
  })

  return (
    <>
      {authInfo.flags.allowEmailPasswordAuth && (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            disabled={Boolean(forcedUsername)}
            label={LL.auth.emailLabel()}
            size='md'
            type='email'
            variant='default'
            {...form.getInputProps('username')}
            autoComplete='email'
            id='email'
            mb='md'
          />
          <PasswordInput
            label={LL.auth.passwordLabel()}
            mt='lg'
            size='md'
            variant='default'
            {...form.getInputProps('password')}
            autoComplete='current-password'
            id='password'
            mb='lg'
          />
          <Anchor<'button'>
            c='primary.6'
            component='button'
            display='block'
            fw='600'
            ml='auto'
            onClick={() => onForgotPassword(form.values.username)}
            size='sm'
            ta='right'
            type='button'
            variant='subtle'
          >
            {LL.auth.forgotPassword()}
          </Anchor>
          {error && (
            <Text c='red' fw='400'>
              {error}
            </Text>
          )}
          <Button fullWidth loading={isLoading} mt='xl' size='md' type='submit'>
            {LL.auth.signIn()}
          </Button>
        </form>
      )}
      {authInfo.identityProviders.length === 0 &&
        !authInfo.flags.allowEmailPasswordAuth && (
          <Text c='gray.9' fw='500' mt='md' size='md'>
            {LL.auth.loginUnavailable()}
          </Text>
        )}
      {authInfo.identityProviders.map(
        ({ displayName, isEnabled, providerName }) => (
          <Button
            disabled={!isEnabled}
            fullWidth
            key={providerName}
            leftSection={<IconIdBadge2 size='1.25rem' />}
            mt='md'
            onClick={() => onSSOLogin(providerName)}
            variant='default'
          >
            {displayName}
          </Button>
        )
      )}
      {authInfo.flags.allowSelfSignUp && (
        <Text c='gray.6' mt='xl' ta='center'>
          <ReactTranslation
            message={LL.auth.signUpCta()}
            renderComponent={(label) => (
              <Anchor<'button'>
                c='primary.6'
                component='button'
                onClick={onSignUp}
                variant='subtle'
              >
                <Text fw='600'>{label}</Text>
              </Anchor>
            )}
          />
        </Text>
      )}
    </>
  )
}

export default LoginForm
