import { sortIndexRegex } from '@packages/aoa-utils'
import {
  type AoaItem,
  AoaItemType,
  AoaProgressStatus,
  type AoaResultDefinition,
  AoaSection,
  type KpiComparison,
  KpiComparisonOptions,
} from '@packages/types'
import _ from 'lodash'
import { type ZodLiteral, z } from 'zod'
import { isoDate, isoDatetime } from './common-shapes'

export const iterationIntervalTypeShape = z.enum(['YEAR', 'QUARTER', 'MONTH'])
export type IterationIntervalType = z.infer<typeof iterationIntervalTypeShape>

export const iterationCycleShape = z.object({
  cycleStart: z.object({
    day: z.number().gte(1).lte(31),
    month: z.number().gte(1).lte(12),
  }),
  earlyTerminationDate: z.string().datetime().optional(),
  iterationCycleId: z.string(),
  tenantId: z.string(),
  terminated: z.boolean().optional(),
})

export type IterationCycle = z.infer<typeof iterationCycleShape>

export const iterationShape = z.object({
  iterationCycleId: z.string().ulid(),
  iterationIntervalType: iterationIntervalTypeShape,
  iterationSequenceNumber: z.number().gte(0).lte(11),
  year: z.number().gte(2_000).lte(2_100),
})

const aoaCommonShape = z
  .object({
    periodEnd: isoDate().optional(),
    periodStart: isoDate().optional(),
    revision: isoDatetime(),
  })
  .refine(
    (schema) =>
      schema.periodEnd === undefined ||
      schema.periodStart === undefined ||
      schema.periodEnd >= schema.periodStart,
    {
      message: 'Period end must be after period start',
      path: ['periodEnd'],
    }
  )

export const aoaSortableShape = z.object({
  sortIndex: z.string().regex(sortIndexRegex).optional(),
})

export const quantifierUnitShape = z.object({
  allowDecimals: z.boolean(),
  symbol: z.string(),
  symbolPosition: z.enum(['BEFORE', 'AFTER']),
})

export type QuantifierUnit = z.infer<typeof quantifierUnitShape>

export const aoaItemFilterShape = z.object({
  aoaIds: z.array(z.string()).optional(),
  aoaPrefilter: z
    .union([z.literal('all'), z.literal('active'), z.literal('expired')])
    .optional(),
  confidenceLevel: z
    .tuple([z.number().min(0).max(1), z.number().min(0).max(1)])
    .optional(),
  dueCheckins: z.boolean().optional(),
  intentLevelFilter: z.array(z.string()).optional(),
  itemType: z.array(z.nativeEnum(AoaItemType)).optional(),
  percentageComplete: z
    .tuple([z.number().min(0).max(100), z.number().min(0).max(100)])
    .optional(),
  responsibleUserId: z.string().optional(),
  status: z.array(z.nativeEnum(AoaProgressStatus)).optional(),
})

const comperatorShape = z.union(
  KpiComparisonOptions.map((option) => z.literal(option)) as [
    ZodLiteral<KpiComparison>,
    ZodLiteral<KpiComparison>,
    ...ZodLiteral<KpiComparison>[],
  ]
)

export const aoaItemCreateResultShape = z.object({
  comperator: comperatorShape.nullable().optional(),
  description: z.string().optional(),
  title: z.string(),
  priority: z.number(),
  referenceLink: z.string().optional(),
  resultType: z.union([z.literal('boolean'), z.literal('kpi')]),
  startValue: z.number().optional().nullable(),
  targetValue: z.number().optional().nullable(),
  unit: quantifierUnitShape.optional().nullable(),
  resultId: z.string().nullable().optional(),
} satisfies Partial<Record<keyof AoaResultDefinition, unknown>>)

export const aoaItemUpdateResultShape = aoaItemCreateResultShape.extend({
  resultId: z.string().nullable().optional(),
})

export const aoaUpdateItemShape = z.intersection(
  aoaCommonShape,
  z.intersection(
    aoaSortableShape,
    z.object({
      itemId: z.string(),
      responsibleUserId: z.string().optional().nullable(),
      tags: z
        .array(z.string().trim())
        .transform((value) => _.uniq(value))
        .optional(),
      text: z.string().trim().min(1).optional(),
      results: z.array(aoaItemUpdateResultShape).optional(),
      description: z.string().optional(),
    })
  )
)

export const aoaItemCreateShape = z.object({
  aoaId: z.string(),
  itemType: z.nativeEnum(AoaItemType),
  parentItemId: z.string().optional(),
  parentItemIds: z.array(z.string()).default([]),
  periodEnd: isoDate().optional(),
  periodStart: isoDate().optional(),
  responsibleUserId: z.string().optional().nullable(),
  section: z.nativeEnum(AoaSection),
  tags: z
    .array(z.string().trim())
    .transform((value) => _.uniq(value))
    .optional(),
  text: z.string().trim(),
  description: z.string().optional(),
  results: z.array(aoaItemCreateResultShape).optional(),
} satisfies Partial<Record<keyof AoaItem, unknown>>)

// const mutateAoaItemShape = z.intersection(
//   aoaUpdateItemShape,
//   aoaItemCreateShape
// )
export type UpdateAoaItemShape = z.infer<typeof aoaUpdateItemShape>
export type CreateAoaItemShape = z.infer<typeof aoaItemCreateShape>
export type MutateAoaItemShape = CreateAoaItemShape | UpdateAoaItemShape

export const aoaInfoShape = z.intersection(
  aoaCommonShape,
  z.object({
    accountableTeamId: z.string().ulid().optional(),
    // Deprecated
    accountableUserId: z.string().optional(),
    aoaId: z.string(),
    iteration: iterationShape.optional(),
    parentAoaIds: z.array(z.string()).optional(),
    title: z.string().trim().optional(),
  })
)

export const checkinShape = z.object({
  progress: z.number().gte(0).lte(1),
  status: z.nativeEnum(AoaProgressStatus),
  confidence: z.number().gte(0).lte(1),
  comment: z.string().optional(),
  itemId: z.string(),
  aoaId: z.string(),
  itemRevision: isoDatetime(),
  checkinData: z.array(
    z.object({
      resultId: z.string(),
      success: z.boolean().nullable(),
      value: z.number().nullable(),
      unchanged: z.boolean().optional(),
    })
  ),
})
