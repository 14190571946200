import IconRingWrapper from '@components/core/IconRingWrapper'
import type { SVGIcon } from '@icons'
import { ActionIcon, Text } from '@mantine/core'
import { closeAllModals } from '@mantine/modals'
import { IconX } from '@tabler/icons-react'
import type { TablerIcon } from '@types'
import { cn } from '@utils'

export type ModalTitleProps = {
  readonly heading: string | null
  readonly icon?: SVGIcon | TablerIcon
  readonly iconRingsHidden?: boolean
  readonly subheading?: string
  readonly textPosition?: 'below' | 'right'
  readonly variant?: 'default' | 'danger' | 'warning'
  readonly withCloseButton?: boolean
  readonly withDivider?: boolean
}

const ModalTitle = ({
  heading,
  subheading,
  icon,
  withCloseButton,
  variant,
  textPosition = 'below',
  iconRingsHidden = false,
  withDivider = true,
}: ModalTitleProps) => {
  const Icon = icon
  return (
    <div>
      <div
        className={cn(
          'px-6 pt-6',
          'flex w-full flex-row justify-between space-x-4 font-sans'
        )}
      >
        <div
          className={cn({
            'grid grid-cols-[auto_1fr] gap-4': textPosition === 'right',
            'space-y-4': textPosition === 'below',
          })}
        >
          <div className='hidden md:block'>
            {Icon && (
              <IconRingWrapper
                icon={Icon}
                ringBgClassName={cn('scale-[0.5]', {
                  hidden: iconRingsHidden,
                })}
                size='3rem'
                variant={variant}
              />
            )}
          </div>
          <div className='my-auto flex flex-col justify-around'>
            {heading && (
              <Text c='gray.9' fw='600' size='lg'>
                {heading}
              </Text>
            )}
            {subheading && (
              <Text c='gray.6' fw='400' size='sm'>
                {subheading}
              </Text>
            )}
          </div>
        </div>
        {withCloseButton !== false && (
          <ActionIcon
            autoFocus={false}
            className='text-gray-400'
            onClick={() => closeAllModals()}
            tabIndex={-1}
            type='button'
          >
            <IconX size={20} />
          </ActionIcon>
        )}
      </div>
      {withDivider && <div className='inset-0 mt-5 h-px w-full bg-gray-200' />}
    </div>
  )
}

export default ModalTitle
