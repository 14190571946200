import { modals } from '@mantine/modals'
import type { TenantLoginInfo } from '@types'
import AuthWrapper from './AuthWrapper'

export type AddAccountModalProps = {
  readonly authflow?: 'signup'
  readonly loginInfo?: TenantLoginInfo
  readonly username?: string
}

const AddAccountModal = (props: AddAccountModalProps) => {
  return (
    <div className='px-4 pb-10'>
      <AuthWrapper
        authflow={props.authflow}
        forcedLoginInfo={props.loginInfo}
        forcedUsername={props.username}
        onComplete={() => modals.closeAll()}
      />
    </div>
  )
}

export default AddAccountModal
