import GsLogo from '@assets/logos/gs-logo-trans.svg'
import OptimizedImage from '@components/core/OptimizedImage'
// @ts-expect-error: importing image
import backgroundImage from '@images/login-background-dark.png?preset=hd'
import { Paper, ScrollArea } from '@mantine/core'
import { cn } from '@utils'
import type { ReactNode } from 'react'
import AccessibilitySettingsMenu from './AccessibilitySettingsMenu'

type LoginLayoutProps = {
  readonly children: ReactNode
}

const LoginLayout = ({ children }: LoginLayoutProps) => {
  return (
    <>
      <OptimizedImage
        alt='Background'
        data={backgroundImage}
        imageProps={{
          className: 'absolute inset-0 w-screen h-screen object-cover',
        }}
      />
      <ScrollArea className='flex h-screen' type='always'>
        <Paper
          className={cn(
            'flex min-h-screen w-full max-w-full flex-col justify-between sm:max-w-md',
            'z-10 bg-white',
            'border-r border-gray-300'
          )}
          display='flex'
          px={30}
          py={30}
          radius={0}
          shadow='lg'
        >
          <div className='-ml-3 block w-72'>
            <GsLogo />
          </div>
          <div className='mb-6 grow'>{children}</div>
          <AccessibilitySettingsMenu />
        </Paper>
      </ScrollArea>
    </>
  )
}

export default LoginLayout
