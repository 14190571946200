import { useAuth, useReload } from '@hooks'
import { Button, PasswordInput, Text } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { useI18nContext } from '@packages/i18n'
import { PasswordRepeatShape } from '@packages/shapes'
import { translateCognitoError } from '@utils/error-utils'
import { confirmResetPassword } from 'aws-amplify/auth'
import { useCallback, useMemo, useState } from 'react'
import { z } from 'zod'

type ResetPasswordSubmitFormProps = {
  readonly onSubmit: (argument0: {
    password: string
    username: string
  }) => Promise<void>
}

const ResetPasswordSubmitForm = ({
  onSubmit,
}: ResetPasswordSubmitFormProps) => {
  const reload = useReload()
  const { configureAmplify } = useAuth()

  const { LL } = useI18nContext()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const initialValues = useMemo(() => {
    const query = new URLSearchParams(window.location.search)
    return {
      confirmation: query.get('confirmation'),
      username: query.get('username'),
    }
  }, [])

  const loginSchema = PasswordRepeatShape(LL, z.object({}))

  const form = useForm({
    initialValues: {
      confirmPassword: '',
      password: '',
    },
    validate: zodResolver(loginSchema),
  })

  const handleSubmit = useCallback(
    async (values: { confirmPassword: string; password: string }) => {
      setIsLoading(true)
      if (values.password === values.confirmPassword) {
        try {
          const { confirmation, username } = initialValues
          if (!(confirmation && username)) {
            reload('/')
            return
          }

          configureAmplify()
          await confirmResetPassword({
            confirmationCode: confirmation,
            newPassword: values.password.trim(),
            username,
          })
          await onSubmit({
            password: values.password.trim(),
            username,
          })
        } catch (error) {
          setErrorMessage(translateCognitoError(LL, error))
        }
      } else {
        form.setFieldError('confirmPassword', 'Passwords do not match')
      }

      setIsLoading(false)
    },
    [LL, configureAmplify, form, initialValues, onSubmit, reload]
  )

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <PasswordInput
        label={LL.auth.passwordLabel()}
        mt='md'
        size='md'
        variant='default'
        {...form.getInputProps('password')}
        autoComplete='new-password'
        mb='md'
      />
      <PasswordInput
        label={LL.auth.confirmPasswordLabel()}
        mt='md'
        placeholder=''
        size='md'
        variant='default'
        {...form.getInputProps('confirmPassword')}
        autoComplete='new-password'
        mb='md'
      />
      <Text c='red' className='min-h-[30px]' fw='600' mt='sm' size='md'>
        {errorMessage}
      </Text>
      <Button fullWidth loading={isLoading} mt='3rem' size='md' type='submit'>
        {LL.auth.changePasswordButton()}
      </Button>
    </form>
  )
}

export default ResetPasswordSubmitForm
