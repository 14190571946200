import type { AuthErrorCodes } from '@aws-amplify/auth/dist/esm/common/AuthErrorStrings'
import type { AuthValidationErrorCode } from '@aws-amplify/auth/dist/esm/errors/types/validation'
import type * as CognitoExceptions from '@aws-amplify/auth/dist/esm/providers/cognito/types/errors'
import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import { AuthError } from 'aws-amplify/auth'

type CognitoExceptionsEnums = typeof CognitoExceptions

type CognitoExceptionCodesMap = {
  [K in keyof CognitoExceptionsEnums]: CognitoExceptionsEnums[K] extends string
    ? CognitoExceptionsEnums[K]
    : keyof CognitoExceptionsEnums[K]
}
type CognitoExceptionCodes =
  CognitoExceptionCodesMap[keyof CognitoExceptionCodesMap]

type ErrorCodes =
  | keyof typeof AuthErrorCodes
  | keyof typeof AuthValidationErrorCode
  | CognitoExceptionCodes

export const translateCognitoError = (
  LL: TranslationFunctions,
  error: unknown
): string => {
  if (!error) return ''
  if (typeof error === 'string') return error
  // if (typeof error === 'object' && error instanceof Error && 'code' in error) {
  if (error instanceof AuthError) {
    const message = error.message

    switch (error.name as ErrorCodes) {
      case 'UserNotFoundException':
        return LL.auth.errors.UserNotFoundException()
      case 'NotAuthorizedException': {
        if (message.includes('Current status is CONFIRMED'))
          return LL.auth.errors.NotAuthorizedException.userAlreadyConfirmed()
        return LL.auth.errors.NotAuthorizedException.unauthorized()
      }
      case 'UsernameExistsException':
        return LL.auth.errors.UsernameExistsException()
      case 'CodeMismatchException':
        return LL.auth.errors.CodeMismatchException()
      case 'LimitExceededException':
        return LL.auth.errors.LimitExceededException()
      case 'EmptySignInUsername':
      case 'InvalidParameterException': {
        if (message.includes('no registered/verified email or phone_number')) {
          return LL.auth.errors.emailNotConfirmedError()
        }

        return LL.auth.errors.InvalidParameterException()
      }
      case 'PasswordResetRequiredException':
        return LL.auth.errors.PasswordResetRequiredException()
      default:
        return error.message
    }
  }

  if (
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  )
    return error.message

  return LL.error.unknownError()
}

/**
 * Utility class we use to hold auto-unwrapped 4xx/5xx Response bodies
 */
class ErrorResponse {
  public status: number

  public statusText: string

  public data: unknown

  public error?: Error

  public internal: boolean

  public constructor(
    status: number,
    statusText: string | undefined,
    data: unknown,
    internal = false
  ) {
    this.status = status
    this.statusText = statusText ?? ''
    this.internal = internal
    if (data instanceof Error) {
      this.data = data.toString()
      this.error = data
    } else {
      this.data = data
    }
  }
}

export const throwNotFoundError = ({ message }: { message: string }) => {
  throw new ErrorResponse(404, message, {
    manuallyThrown: true,
  })
}

export const throwUnknownError = ({
  message,
  statusCode,
}: {
  message: string
  statusCode?: number
}) => {
  throw new ErrorResponse(statusCode ?? 500, message, {
    manuallyThrown: true,
  })
}
