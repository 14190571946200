import AccessibilitySettingsMenu from '@components/layout/AccessibilitySettingsMenu'
import TenantAvatar from '@components/organization/tenant/TenantAvatar'
import { useAuth } from '@hooks'
import { Button, Text, TextInput, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useI18nContext } from '@packages/i18n'
import { tenantDisplayName, tenantInitials } from '@packages/shapes'
import { isTRPCClientError, trpc } from '@utils/trpc'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

const OnboardingPage = () => {
  const navigate = useNavigate()
  const { LL } = useI18nContext()

  const { reloadAccount, switchUser, user, clearTenant } = useAuth()

  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync } = trpc.account.createTenant.useMutation()

  const shape = z.object({
    displayName: tenantDisplayName(LL),
    initials: tenantInitials(LL),
  })

  const form = useForm<z.infer<typeof shape>>({
    initialValues: {
      displayName: '',
      initials: '',
    },
    validate: zodResolver(shape),
  })

  const handleExit = useCallback(() => {
    navigate('/', { replace: true })
  }, [navigate])

  useEffect(() => {
    clearTenant()
  }, [clearTenant])

  const handleSubmit = useCallback(
    async (values: z.infer<typeof shape>) => {
      if (isLoading) return

      setIsLoading(true)

      await mutateAsync({
        ...values,
        initials: values.initials,
      })
        .then(async (response) => {
          await reloadAccount({ forceRefresh: true }).then(async () => {
            if (user?.username)
              await switchUser({
                navigate,
                tenantId: response.tenantId,
                username: user.username,
              })
          })
        })
        .catch((error) => {
          if (isTRPCClientError(error)) {
            showNotification({
              color: 'red',
              message: error.message,
              title: LL.organization.createOrganization.failedToCreate(),
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [
      LL.organization.createOrganization,
      isLoading,
      mutateAsync,
      navigate,
      reloadAccount,
      switchUser,
      user?.username,
    ]
  )

  return (
    <form
      className='flex min-h-screen w-full flex-col bg-gray-50 px-4 pb-10 pt-4'
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <Button
        color='gray'
        display='block'
        ml='auto'
        onClick={handleExit}
        variant='white'
      >
        {LL.buttons.cancel()}
      </Button>
      <div className='my-auto grid h-full place-items-center'>
        <Title mb={4} order={4} ta='center'>
          {LL.organization.createOrganization.headline()}
        </Title>
        <Text c='dimmed' mb={35}>
          {LL.organization.createOrganization.headlineDescription()}
        </Text>
        <div className='mb-6 shadow'>
          <TenantAvatar
            authInfo={{
              initials: form.values.initials,
            }}
            avatarProps={{
              size: 'xl',
            }}
          />
        </div>
        <div className='w-72 max-w-full'>
          <TextInput
            label={LL.organization.createOrganization.organizationName()}
            {...form.getInputProps('displayName')}
            id='displayName'
            placeholder='Agile Company Ltd.'
          />
          <Button
            fullWidth
            loading={isLoading}
            mt='2rem'
            size='md'
            type='submit'
          >
            {LL.organization.createOrganization.button()}
          </Button>
        </div>
      </div>
      <AccessibilitySettingsMenu />
    </form>
  )
}

export default OnboardingPage
