import { fontFamily as defaultFontFamily } from 'tailwindcss/defaultTheme'

const screens = {
  '2xl': '1536px',
  lg: '1024px',
  md: '768px',
  sm: '640px',
  xl: '1280px',
  xs: '480px',
}

// const primaryFont = 'haboro-soft'
const primaryFont = 'Inter var'
const textContentFont = 'Inter var'

const fontFamily = {
  headings: [
    primaryFont,
    'Roboto',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ],
  mono: defaultFontFamily.mono,
  sans: [
    textContentFont,
    'Roboto',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ],
}

const lineHeight = {
  'd-2xl': '5.625rem',
  'd-xl': '4.5rem',
  'd-lg': '3.75rem',
  'd-md': '2.75rem',
  'd-sm': '2.375rem',
  'd-xs': '2rem',
  xl: '1.875rem',
  lg: '1.75rem',
  md: '1.5rem',
  sm: '1.25rem',
  xs: '1.125rem',
}

const fontSize = {
  base: '1rem',
  'd-2xl': '4.5rem',
  'd-xl': '3.75rem',
  'd-lg': '3rem',
  'd-md': '2.25rem',
  'd-sm': '1.875rem',
  'd-xs': '1.5rem',
  xl: '1.25rem',
  lg: '1.125rem',
  sm: '0.875rem',
  xs: '0.75rem',
}

const combinedFontSizes = {
  base: [fontSize.base, lineHeight.md],
  'd-2xl': [fontSize['d-2xl'], lineHeight['d-2xl']],
  'd-lg': [fontSize['d-lg'], lineHeight['d-lg']],
  'd-md': [fontSize['d-md'], lineHeight['d-md']],
  'd-sm': [fontSize['d-sm'], lineHeight['d-sm']],
  'd-xl': [fontSize['d-xl'], lineHeight['d-xl']],
  'd-xs': [fontSize['d-xs'], lineHeight['d-xs']],
  lg: [fontSize.lg, lineHeight.lg],
  sm: [fontSize.sm, lineHeight.sm],
  xl: [fontSize.xl, lineHeight.xl],
  xs: [fontSize.xs, lineHeight.xs],
}

const shadows = {
  '2xl': '0px 24px 48px -12px rgba(16,24,40,0.18)',
  '3xl': '0px 32px 64px -12px rgba(16,24,40,0.14)',
  lg: [
    '0px 4px 6px -2px rgba(16,24,40,0.03)',
    '0px 12px 16px -4px rgba(16,24,40,0.08)',
  ],
  md: [
    '0px 2px 4px -2px rgba(16,24,40,0.06)',
    '0px 4px 8px -2px rgba(16,24,40,0.1)',
  ],
  none: 'none',
  sm: [
    '0px 1px 2px 0px rgba(16,24,40,0.06)',
    '0px 1px 3px 0px rgba(16,24,40,0.1)',
  ],
  xl: [
    '0px 8px 8px -4px rgba(16,24,40,0.03)',
    '0px 20px 24px -4px rgba(16,24,40,0.08)',
  ],
  xs: '0px 1px 2px 0px rgba(16,24,40,0.05)',
}

export {
  combinedFontSizes,
  fontFamily,
  fontSize,
  lineHeight,
  primaryFont,
  screens,
  shadows,
  textContentFont,
}
