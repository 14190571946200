import BrandLogo from '@assets/logos/gs-logo-trans.svg'
import AuthWrapper from '@components/auth/AuthWrapper'
import TenantListItem from '@components/auth/TenantListItem'
import UserTenantList from '@components/auth/UserTenantList'
import IconRingWrapper from '@components/core/IconRingWrapper'
import ErrorResponsePreview from '@components/core/faulty-states/ErrorResponsePreview'
import { useAuth } from '@hooks'
import { usePermissions } from '@hooks/usePermissions'
import { Button, Loader, Menu, Title } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import {
  IconBrandTeams,
  IconCircleCheck,
  IconKeyFilled,
  IconX,
  type TablerIcon,
} from '@tabler/icons-react'
import { trpc } from '@utils/trpc'
import type { ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Shell = ({
  children,
  description,
  headline,
  icon,
}: {
  children: ReactNode
  icon: TablerIcon | null
  headline: string | null
  description: string | null
}) => {
  return (
    <div>
      <div className='grid h-screen justify-items-center items-start'>
        <div className='flex justify-center z-10'>
          <BrandLogo className='w-64 mt-12' />
        </div>
        <div className='mx-6 pb-48 max-w-96 text-center grid place-items-center '>
          {icon ? (
            <IconRingWrapper
              iconProps={{
                color: '#454cb2',
                height: 28,
                width: 28,
                strokeWidth: 1.5,
              }}
              icon={icon}
              pattern='squares'
              size={'56px'}
            />
          ) : null}
          <div className='z-10 mt-6'>
            {!headline && !description ? null : (
              <>
                <h1 className='mb-3 text-d-xs text-gray-900 font-semibold'>
                  {headline}
                </h1>
                <p className='mb-6 text-md font-normal text-gray-600'>
                  {description}
                </p>
              </>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const LinkMsTeamsPage = () => {
  const { LL } = useI18nContext()
  const { tenant, user } = useAuth<false>()

  const [params] = useSearchParams()
  const teamsId = params.get('teamsId')

  const { invalidate } = trpc.useUtils().organization.listTenantTeamsConnections

  const { data } = trpc.organization.listTenantTeamsConnections.useQuery(
    undefined,
    { enabled: Boolean(user) }
  )
  const connectionExists = data?.connections.some(
    (connection) => connection.teamsId === teamsId
  )

  const { authorized } = usePermissions(['Settings:Update', 'Settings:Create'])

  const navigate = useNavigate()

  const { mutate, isPending, isSuccess, error } =
    trpc.organization.createTeamsConnection.useMutation({
      onSettled: () => {
        invalidate()
      },
    })

  if (!teamsId) {
    navigate('/')
    return null
  }

  if (!user)
    return (
      <div className='grid place-items-center'>
        <Shell headline={null} description={null} icon={IconBrandTeams}>
          <div className='text-left'>
            <AuthWrapper onComplete={() => null} authflow={undefined} />
          </div>
        </Shell>
      </div>
    )

  if (!tenant)
    return (
      <div className='grid place-items-center'>
        <Shell headline={null} description={null} icon={IconBrandTeams}>
          <Title mb='lg' ml='xs' order={5}>
            {LL.auth.selectOrganization()}
          </Title>
          <div className='max-w-72 mx-auto bg-gray-50 rounded'>
            <Menu>
              <UserTenantList
                navigateTo={window.location.pathname + window.location.search}
              />
            </Menu>
          </div>
        </Shell>
      </div>
    )

  if (error)
    return (
      <div className='grid place-items-center'>
        <ErrorResponsePreview
          error={error}
          showErrorMessage
          headlineOverride='Failed to connect Microsoft Teams'
        />
      </div>
    )

  if (isSuccess) {
    return (
      <Shell
        headline='Microsoft Teams connected'
        description='This Microsoft Teams Organization is now connected to this account.'
        icon={IconCircleCheck}
      >
        <Button
          color='primary'
          onClick={() => {
            navigate('/', { replace: true })
          }}
        >
          Take me to the start page
        </Button>
      </Shell>
    )
  }

  if (connectionExists === undefined) {
    return (
      <Shell headline={null} description={null} icon={null}>
        <Loader />
      </Shell>
    )
  }

  if (connectionExists) {
    return (
      <Shell
        headline='Microsoft Teams already connected'
        description='This Microsoft Teams Organization is already connected to this account.'
        icon={IconCircleCheck}
      >
        <Button
          className='!bg-ms-teams'
          onClick={() => {
            navigate('/', { replace: true })
          }}
        >
          Take me to the start page
        </Button>
      </Shell>
    )
  }

  if (!authorized) {
    return (
      <Shell
        headline='Insufficient Permissions'
        description='You do not have the required permissions to link Microsoft Teams. Ask your organization to authorize the GrowthSquare Microsoft Teams app.'
        icon={IconX}
      >
        <Button
          color='primary'
          onClick={() => {
            navigate('/', { replace: true })
          }}
        >
          Take me to the start page
        </Button>
      </Shell>
    )
  }

  return (
    <Shell
      headline='Authorization Request'
      description='The GrowthSquare Microsoft Teams app will be granted access to your organizations strategy and data.'
      icon={IconBrandTeams}
    >
      <div className='text-left flex justify-center mb-8'>
        <div>
          <div className='text-sm font-semibold text-gray-600 mb-1'>
            Selected Organization
          </div>
          <div className='pointer-events-none bg-gray-50 border-gray-200 border rounded w-64'>
            <TenantListItem
              tenantInfo={tenant.tenant}
              subText={LL.organization.memberCount(tenant.memberCount)}
            />
          </div>
        </div>
      </div>
      <Button
        className='!bg-ms-teams'
        rightSection={<IconKeyFilled className='size-4' />}
        loading={isPending}
        onClick={() => {
          mutate({ teamsId })
        }}
      >
        Authorize Microsoft Teams
      </Button>
    </Shell>
  )
}

export default LinkMsTeamsPage
