import type { BaseTranslation } from 'typesafe-i18n'

const en_ai = {
  ai: {
    welcomeTitle: 'Welcome to the GrowthSquare Strategy AI',
    welcomeDescription:
      "I'm here to assist you with questions about the strategy of your organization.",
    sources: 'Sources',
    responseDetails: {
      menuLabel: 'Response Details',
      tokenUsage: 'Consumed Input / Output Tokens',
      responseDuration: 'Response Duration',
      responseError: 'Error',
    },
    startNewConversation: 'Start a new conversation',
  },
} as const satisfies BaseTranslation

export default en_ai
