import type { IterationCycle } from '@packages/shapes'
import { AoaApprovableStatus, type AoaInfo, type DT } from '@packages/types'
import _ from 'lodash'
import { isActiveAoa, isExpiredAoa } from './iteration-utils'

export const showRelevantAoaForSeries = <
  T extends {
    aoaId: string
    iteration?: AoaInfo['iteration']
    level: number
    seriesId?: string | undefined
    status: AoaApprovableStatus
  },
>(
  aoas: Readonly<T[]>,
  cycles: (IterationCycle & DT)[] | undefined,
  options?: {
    showActiveAoasOnly?: boolean
  }
): T[] => {
  const aoaBySeries = _(aoas).groupBy('seriesId').value()

  const result: T[] = []

  for (const aoaList of Object.values(aoaBySeries)) {
    if (!aoaList) continue

    const firstNotExpiredAoa = aoaList.find(
      (aoa) =>
        !isExpiredAoa({ cycles, iteration: aoa.iteration }) && aoa.seriesId
    )

    const firstActiveAoa = aoaList.find(
      (aoa) =>
        isActiveAoa(
          aoa,
          cycles,
          aoaList.filter((item) => item.seriesId === aoa.seriesId)
        ) && aoa.seriesId
    )

    if (firstActiveAoa) result.push(firstActiveAoa)
    else if (!options?.showActiveAoasOnly && firstNotExpiredAoa)
      result.push(firstNotExpiredAoa)
    else if (!options?.showActiveAoasOnly) {
      const lastExpiredAoa = aoaList.at(-1)
      if (lastExpiredAoa) result.push(lastExpiredAoa)
    }
  }

  for (const aoa of aoas) {
    if (!aoa.seriesId) result.push(aoa)
  }

  return result
}

export const filterAoasByActiveStatus =
  ({
    status,
    cycles,
  }: {
    cycles: Array<IterationCycle & DT>
    status: 'active' | 'draft' | 'archive'
  }) =>
  (aoa: AoaInfo, series: AoaInfo[]) => {
    const isExpired = isExpiredAoa({ cycles, iteration: aoa.iteration })
    if (status === 'draft' && aoa.status === AoaApprovableStatus.Draft)
      return !isExpired

    const isActive = isActiveAoa(aoa, cycles, series)
    if (
      status === 'active' &&
      isActive &&
      aoa.status === AoaApprovableStatus.Approved
    )
      return true
    if (
      status === 'archive' &&
      !isActive &&
      (aoa.status !== AoaApprovableStatus.Draft || isExpired)
    )
      return true
    return false
  }

export const getFilteredAoaListByActiveStatus = ({
  aoaList,
  iterationCycles,
  status,
}: {
  aoaList: Array<AoaInfo & DT>
  iterationCycles: Array<IterationCycle & DT> | undefined
  status?: 'active' | 'draft' | 'archive' | string | undefined
}) => {
  const relevantAoas = showRelevantAoaForSeries(aoaList, iterationCycles)
  return relevantAoas.filter(
    status === undefined
      ? () => true
      : (aoa) =>
          filterAoasByActiveStatus({
            cycles: iterationCycles ?? [],
            status: ['active', 'draft', 'archive'].includes(status)
              ? (status as 'active' | 'draft' | 'archive')
              : 'active',
          })(
            aoa,
            aoaList.filter((item) => item.seriesId === aoa.seriesId)
          )
  )
}
