import OrganizationIcon from '@icons/building-05.svg'
import type { AvatarProps } from '@mantine/core'
import type { TenantLoginInfo } from '@types'
import { cn } from '@utils'
import type { ReactNode } from 'react'
import CommonAvatar from '../user/CommonAvatar'

type TenantAvatarProps = {
  readonly authInfo: Pick<TenantLoginInfo, 'initials' | 'logoSquareUrl'>
  readonly avatarProps?: Omit<AvatarProps, 'children' | 'src'>
}

const TenantAvatar = (props: TenantAvatarProps) => {
  const source: string | undefined = props.authInfo.logoSquareUrl
  const fallBackItem: ReactNode = props.authInfo.initials ? (
    props.authInfo.initials
  ) : (
    <OrganizationIcon height='55%' width='55%' />
  )

  return (
    <CommonAvatar
      avatarProps={{
        radius: 'sm',
        ...props.avatarProps,
        className: cn('text-xl', props.avatarProps?.className),
      }}
      fallback={fallBackItem}
      url={source}
    />
  )
}

export default TenantAvatar
