import { useLocalStorage } from '@mantine/hooks'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import type { TRPCClientError } from '@trpc/client'
import { retryExcept404, trpc, trpcLinks } from '@utils/trpc'
import { type ReactNode, useMemo } from 'react'
import type { AppRouter } from '../../../../backend'

export type ApiProviderProps = {
  readonly children: ReactNode
}

const trpcReactQueryClient = trpc.createClient({
  links: trpcLinks,
})

export const ApiProvider = ({ children }: ApiProviderProps) => {
  const [tenantId] = useLocalStorage({
    getInitialValueInEffect: false,
    key: 'tenant-id',
  })
  const apiVersion = 'v1'

  const queryClient = useMemo(() => {
    const client = new QueryClient({
      defaultOptions: {
        mutations: { gcTime: 0 },
        queries: {
          // 5 minutes
          gcTime: 1_000 * 60 * 5,
          refetchOnWindowFocus: false,
          retry: (count, error) =>
            retryExcept404(
              count,
              error as unknown as Pick<TRPCClientError<AppRouter>, 'data'>
            ),

          // 0 seconds
          staleTime: 0,
        },
      },
    })
    const localStoragePersister = createSyncStoragePersister({
      key: ['trpc-cache', apiVersion, tenantId].join('-'),
      storage: window.localStorage,
    })

    void persistQueryClient({
      buster: [apiVersion].join(':'),
      // 1 hour
      maxAge: 1_000 * 60 * 60,
      persister: localStoragePersister,
      queryClient: client,
    })
    return client
  }, [tenantId])

  return (
    <trpc.Provider client={trpcReactQueryClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  )
}
