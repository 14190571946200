import openBaseModal from '@components/modals/openBaseModal'
import { IconMailOpened } from '@tabler/icons-react'
import AcceptInvitationModal, {
  type AcceptInvitationModalProps,
} from './AcceptInvitationModal'

export const openAcceptInvitationModal = ({
  heading,
  ...props
}: AcceptInvitationModalProps & { heading: string }) =>
  openBaseModal({
    children: <AcceptInvitationModal {...props} />,
    title: {
      heading,
      icon: IconMailOpened,
      textPosition: 'right',
    },
  })
