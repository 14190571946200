import { useProvideBranding } from '@hooks'
import { MantineProvider } from '@mantine/core'
import type { ReactElement, ReactNode } from 'react'
import { BrandingContext } from './BrandingContext'

const BrandingProvider = (props: {
  readonly children: ReactNode
}): ReactElement => {
  const value = useProvideBranding()

  return (
    <MantineProvider defaultColorScheme='light' theme={value?.mantineTheme}>
      <BrandingContext.Provider value={value}>
        {props.children}
      </BrandingContext.Provider>
    </MantineProvider>
  )
}

export default BrandingProvider
