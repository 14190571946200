import type { Translation } from '../i18n-types'

const de_org = {
  organization: {
    createOrganization: {
      alreadyExists: 'Eine Organisation mit diesem Kürzel existiert bereits',
      button: 'Organisation erstellen',
      failedToCreate: 'Fehler beim Erstellen der Organisation',
      headline: 'Willkommen bei GrowthSquare',
      headlineDescription: 'Erstelle eine neue Organisation, um loszulegen.',
      initials: 'Initialen',
      organizationName: 'Organisationsname',
      tenantAlias: 'Organisationskürzel',
      tenantAliasExplanation:
        'Dieses Kürzel wird verwendet, um die URL für deine Organisation zu erstellen. Es darf nur aus Buchstaben und Zahlen bestehen.',
      tenantSubdomain: 'Organisations Domain',
      tenantSubdomainExplanation:
        'Diese Subdomain wird verwendet, um eine eindeutige URL für deine Organisation zu erstellen.',
    },
    inviteUser: 'Benutzer einladen',
    inviteUserModal: {
      inviteButton: 'Einladen',
      inviteErrorDescription:
        'Versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
      inviteErrorHeadline: 'Benutzer konnten nicht eingeladen werden',
      inviteSuccessDescription:
        '{0} Benutzer wurden erfolgreich zu {1} eingeladen',
      inviteSuccessHeadline: 'Benutzer erfolgreich eingeladen',
      listHeader: {
        default: 'Wähle eine Person aus',
        keepTyping: 'Weiter tippen, um E-Mail einzuladen',
        selectUser: 'Benutzer auswählen, um einzuladen',
      },
      resultBox: {
        noSearchTerm: 'Suche nach Benutzern oder füge E-Mails oben ein',
      },
      searchPlaceholder: 'Suche nach Namen oder E-Mail-Adressen',
      userAlreadyExists:
        'Dieser Benutzer ist bereits Mitglied dieser Organisation.',
      userAlreadyExistsDetailed: '{name} ist bereits Mitglied von {org}',
      userAlreadyInvited:
        'Dieser Benutzer wurde bereits zu dieser Organisation eingeladen.',
    },
    team: {
      headline: 'Team',
      teamFailedToLoad: 'Fehler beim Laden des Teams',
      teamNotFound: 'Team nicht gefunden',
    },
    memberCount: '{0} Mitglied{{er}}',
    memberDetail: {
      accessRole: 'Zugriffsrolle',
      roles: {
        adminDescription: 'Kann alle Organisationseinstellungen verwalten',
        adminLabel: 'Admin',
        noAccessDescription:
          'Nur Mitgliederliste anzeigen, kein Zugriff auf Organisationsdaten',
        noAccessLabel: 'Kein Zugriff',
        viewerDescription:
          'Kann alles in der Organisation anzeigen und Check-ins durchführen',
        viewerLabel: 'Viewer',
      },
      updateUserRoleErrorDescription:
        'Versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich an den Support.',
      updateUserRoleErrorTitle: 'Benutzer konnte nicht aktualisiert werden',
      updateUserRoleSuccessDescription:
        '{0} wurde die neue Rolle erfolgreich zugewiesen',
      updateUserRoleSuccessTitle: 'Benutzer erfolgreich aktualisiert',
    },
    orgChart: {
      addSubTeam: 'Untergeordnetes Team hinzufügen',
      addTeamMember: 'Teammitglied hinzufügen',
      addToTeamButton: 'Zum Team hinzufügen',
      color: 'Farbe',
      createTeam: 'Team erstellen',
      createTeamDescription: 'Erstelle ein neues Team in deiner Organisation',
      createTeamHeadline: 'Neues Team',
      deleteTeam: 'Team löschen',
      displayName: 'Teamname',
      inheritedFrom: 'Übernommen von {0}',
      inheritSubTeamMembers: 'Untergeordnete Teammitglieder übernehmen',
      inheritSubTeamMembersDescription:
        'Alle Teammitglieder, die zu einem untergeordneten Team gehören, werden auch diesem Team zugeordnet.',
      noTeamAssigned: 'Kein Team zugewiesen',
      noTeamLead: 'Kein Teamleiter zugewiesen',
      noTeamMembers: 'Keine Teammitglieder',
      noTeamsCreatedDescription: 'Erstelle dein erstes Team',
      noTeamsCreatedHeadline: 'Keine Teams erstellt',
      noTeamsCta: 'Loslegen',
      noTeamsFoundError: 'Keine Teams gefunden',
      teamLead: 'Teamleiter',
      teamMembers: 'Teammitglieder',
      teamMembersDescription:
        'Folgende Personen sind diesem Team direkt zugewiesen.',
      updateTeam: 'Team bearbeiten',
      updateTeamDescription: 'Bearbeite ein Team in deiner Organisation',
      updateTeamHeadline: 'Team bearbeiten',
      viewMultipleAoa: '{0} zugewiesene AOAs anzeigen',
      noLinkedAoas: 'Keine AOA mit diesem Team verknüpft',
      linkedAoas: 'Verknüpfte AOAs',
      parentTeam: 'Übergeordnetes Team',
      subTeams: 'Untergeordnete Teams',
      viewSingleAoa: 'Zu {0}',
    },
    pageHeader: 'Organisationsmitglieder',
    pageSubheader: 'Verwalte die Mitglieder deiner Organisation',
    profile: {
      backToMembers: 'Zurück zu den Mitgliedern',
      addToTeamButton: 'Zu Team hinzufügen',
      displayName: 'Anzeigename',
      email: 'E-Mail',
      failedToUpdateTeamMemberships:
        'Teammitgliedschaften konnten nicht aktualisiert werden, bitte versuchen Sie es erneut',
      firstName: 'Vorname',
      headline: 'Profil',
      lastName: 'Nachname',
      noTeamsAvailable: 'Keine Teams verfügbar',
      phoneNumber: 'Telefonnummer',
      teamMemberships: 'Teammitgliedschaften',
    },
    usersPageHeader: 'Benutzer',
    userTable: {
      activeUsers: 'Aktiv',
      allUsers: 'Alle Benutzer',
      byTeamListSubheader: 'Filtere Benutzer nach Team oder suche nach Namen',
      empty: 'Keine Benutzer gefunden',
      emptyInviteDescription:
        'Lade Benutzer ein, um sie deiner Organisation hinzuzufügen',
      emptyState: {
        clearCategoryCta: 'Alle Benutzer anzeigen',
        clearSearchCta: 'Suche löschen',
        descriptions: {
          emptyList: 'Es sind keine gültigen Benutzer verfügbar.',
          noActiveUsers:
            'Es gibt keine aktiven Benutzer in Ihrer Organisation.',
          noInactiveUsers:
            'Es gibt keine inaktiven Benutzer in Ihrer Organisation.',
          noInvitedUsers:
            'Sie haben noch keine Benutzer zu Ihrer Organisation eingeladen.',
          noResults:
            'Ihre Suche "{searchQuery}" ergab keine Übereinstimmungen. Bitte versuchen Sie es erneut.',
          noResultsActiveUsers:
            'Ihre Suche "{searchQuery}" ergab keine Übereinstimmungen mit aktiven Benutzern. Bitte versuchen Sie es erneut.',
          noResultsInactiveUsers:
            'Ihre Suche "{searchQuery}" ergab keine Übereinstimmungen mit inaktiven Benutzern. Bitte versuchen Sie es erneut.',
          noResultsInvitedUsers:
            'Ihre Suche "{searchQuery}" ergab keine Übereinstimmungen mit eingeladenen Benutzern. Bitte versuchen Sie es erneut.',
        },
        inviteUsersCta: 'Benutzer einladen',
        noResultsHeader: 'Keine Benutzer gefunden',
      },
      inactiveUsers: 'Inaktiv',
      invitedUsers: 'Eingeladen',
      membersWithoutTeams: 'Mitglieder ohne Teams',
      menu: {
        activateUserConfirmation: 'Möchten Sie {0} wirklich aktivieren?',
        activateUserError: 'Benutzer {0} konnte nicht aktiviert werden',
        activateUserLabel: 'Benutzer aktivieren',
        activateUserSuccess: 'Benutzer {0} wurde erfolgreich aktiviert',
        deleteInvitation: 'Einladung löschen',
        disableUserConfirmation: 'Möchten Sie {0} wirklich deaktivieren?',
        disableUserError: 'Benutzer {0} konnte nicht deaktiviert werden',
        disableUserLabel: 'Benutzer deaktivieren',
        disableUserSuccess: 'Benutzer {0} wurde erfolgreich deaktiviert',
        resendInvitation: 'Einladung erneut senden',
        resendInvitationSkipped:
          'Einladung wurde bereits erneut gesendet, bitte versuchen Sie es später erneut',
        resendInvitationSuccess: 'Einladung erfolgreich erneut gesendet',
        scimAccountInactive:
          'Dieser Benutzer wurde in Ihrem Identitätsanbieter deaktiviert. Kontaktieren Sie den Support für weitere Informationen.',
        userAccountInactive:
          'Dieses Konto ist deaktiviert, bitte kontaktieren Sie den Support',
        viewProfile: 'Profil ansehen',
      },
      nameLabel: 'Name',
      roleLabel: 'Rolle',
      statusLabel: 'Status',
      teams: 'Teams',
      userCount: '{0} Mitglied{{er}}',
    },
  },
} satisfies Pick<Translation, 'organization'>

export default de_org
