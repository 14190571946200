import type { Translation } from '../i18n-types'

const de_settings = {
  settingsPage: {
    headline: 'Einstellungen',
    methodology: {
      checkinDueFrequencies: {
        biweekly: 'Zweiwöchentlich (empfohlen)',
        headline: 'Check-in Fälligkeitsfrequenzen',
        intro: 'Wähle aus, nach welcher Frequenz Check-ins fällig sind',
        monthly: 'Monatlich',
        off: 'Nie',
        weekly: 'Wöchentlich',
      },
      errors: {
        updateFailed: 'Fehler beim Aktualisieren der Methodikeinstellungen',
      },
      headline: 'Methodik',
      intro:
        'Passe an, wie Sie die AOA-Methodik in ihrer Organisation anwenden. Diese Einstellungen gelten für alle Mitglieder der Organisation.',
      iterationCycles: {
        cycleStartDateDescription:
          'Sie können bei bedarf den Start der Iterationen ändern, beispielsweise wenn Ihr Geschäftsjahr nicht am 1. Januar beginnt.',
        cycleStartDateLabel: 'Jährlicher Iterationsstart',
        cycleStartDatePlaceholder: 'Standard (1. Januar)',
        earlyTermination:
          'Diese Änderung führt dazu, dass die aktuelle Iteration am {0} vorzeitig endet.',
        headline: 'Iterationszyklen',
        intro:
          'Legen Sie fest, wann Ihre Iterationszyklen beginnen und enden. Dies hat keine Auswirkungen auf vergangene Iterationen.',
        iterationNamePlaceholder: 'Standard',
        legacyCycle: 'Alter Zyklus',
        monthIteration: '{year} - Monatliche Iteration {month}',
        monthIterationNameLabel: 'Monatlicher Iterationsname',
        quarterIterationNameLabel: 'Quartals-Iterationsname',
        quaterIteration: '{year} - Quartals-Iteration {quater}',
        yearlyIteration: 'Jährliche Iteration {0}',
        yearlyIterationNameLabel: 'Jährlicher Iterationsname',
      },
      updateSuccess: 'Methodikeinstellungen aktualisiert',
    },
    notifications: {
      checkins: {
        frequencies: {
          biweekly: 'Zweiwöchentlich',
          inherited: 'Standard',
          inheritedDescription: 'Standard der Organisation verwenden',
          monthly: 'Monatlich',
          off: 'Benachrichtigungen deaktivieren',
          weekly: 'Wöchentlich',
        },
        headline: 'Check-In Erinnerungen',
        intro:
          'Erhalte eine Erinnerung zum Check-In, wenn du dies nicht getan hast',
      },
      headline: 'Benachrichtigungen',
      subheader: 'Passe an, wie du Benachrichtigungen erhältst',
    },
    organization: {
      communication: {
        checkinReminderEmails: 'Check-in-Erinnerungs-E-Mails',
        defaultReminderInterval: 'Standard-Erinnerungsintervall',
        defaultReminderIntervalDescription:
          'Das Standard-Erinnerungsintervall für Mitglieder der Organisation',
        failedToUpdate:
          'Fehler beim Aktualisieren der Kommunikationseinstellungen',
        headline: 'Kommunikation',
        intro:
          'Aktualisieren Sie Ihre Kommunikationseinstellungen für die Organisation',
        reminderEmailsDescription:
          'Mitglieder der Organisation an fehlende Check-ins per E-Mail erinnern',
        sendMonthlyAtEnd: 'Zum Monatsende',
        sendMonthlyAtStart: 'Zum Monatsanfang',
        timeOfDay: 'Tageszeit, zu der Erinnerungs-E-Mails gesendet werden',
        timeOfDayDescription:
          'Die Zeit wird in Ihrer lokalen Zeitzone angegeben',
        updated: 'Kommunikationseinstellungen aktualisiert',
        weekDay: 'Wochentag',
        weekDayDescription:
          'Der Wochentag, an dem Erinnerungs-E-Mails gesendet werden',
      },
      displayName: {
        displayName: 'Anzeigename',
        failedToUpdate: 'Fehler beim Aktualisieren des Firmennamens',
        headline: 'Organisationsname',
        intro: 'Wie Ihre Organisation in der App angezeigt wird',
        updated: 'Organisationsname aktualisiert',
      },
      domain: {
        addAdditionalDomain: 'Weitere Domain hinzufügen',
        createNewDomain: 'Neue Domain erstellen',
        createNewDomainDescription:
          'Erlaube den Mitgliedern deiner Organisation, sich mit einer eindeutigen URL anzumelden.',
        domainLabel: 'Domain',
        headline: 'Anmeldedomain der Organisation',
        intro: 'Eine eindeutige Anmelde-URL für Ihre Organisation',
        limitReached:
          'Sie haben das Limit an Domains erreicht, die Sie erstellen können',
        noDomainsCreatedDescription: 'Sie haben noch keine Domains erstellt',
        noDomainsCreatedTitle: 'Keine Domains erstellt',
        subdomainAlreadyExists: 'Diese Subdomain existiert bereits',
      },
      msTeamsConnections: {
        headline: 'Microsoft Teams Integration',
        intro: 'Verbinde deine Organisation mit Microsoft Teams',
        noTeamsConnnectionsCreatedDescription:
          'Sie haben noch keine Verbindungen zu Microsoft Teams eingerichtet.',
        noTeamsConnnectionsCreatedTitle: 'Keine Teams-Integration eingerichtet',
        commentLabel: 'Bemerkung',
        downloadApp: 'App herunterladen',
        addAppToTeamsHeader: 'GrowthSquare zu Microsoft Teams hinzufügen',
        manageConnectionHeader: 'Microsoft Teams-Verbindung verwalten',
        authenticateTeamsHeader: 'Zugriff von Microsoft Teams freigeben',
        introDescription:
          'Erlaube den Mitgliedern ihrer Organisation, mit der Strategie ihrer Organisation über einen KI-gestützten Chatbot in Microsoft Teams zu interagieren.',
      },
      headline: 'Organisation',
      logo: {
        failedToUpdate: 'Fehler beim Aktualisieren des Firmenlogos',
        headline: 'Firmenlogo',
        intro:
          'Laden Sie ein Logo für Ihre Organisation hoch, das in der App angezeigt wird',
      },
      sso: {
        headline: 'Single sign-on',
        initialRolesLabel: 'Initiale Rolle',
        intro:
          'Erlaube den Mitgliedern deiner Organisation, sich mit ihrem zentralen Login anzumelden.',
        noSsoConfiguredDescription:
          'SSO ist noch nicht für Ihre Organisation eingerichtet',
        noSsoConfiguredTitle: 'Kein SSO konfiguriert',
        providerLabel: 'Provider',
        scim: {
          baseUrl: 'Endpunkt Basis-URL',
          configureScimHeader: 'Verzeichnissynchronisierung konfigurieren',
          configureScimIntro:
            'Verwenden Sie SCIM, um Benutzer von Ihrem IdP zu synchronisieren. Dadurch können Sie Benutzer auswählen, die die Plattform noch nicht verwenden.',
          configureScimMenuItem: 'Verzeichnissynchronisierung verwalten',
          enabledAt: 'Eingerichtet am {0}',
          renewConfirm:
            'Sind Sie sicher, dass Sie das Token erneuern möchten? Dies macht das alte Token ungültig und Sie müssen es in Ihrem IdP aktualisieren.',
          renewToken: 'Token erneuern',
          scimNotConfiguredDescription:
            'Konfigurieren Sie einen SCIM v2-Endpunkt, um Benutzer von Ihrem IdP zu synchronisieren.',
          scimNotConfiguredTitle: 'SCIM nicht konfiguriert',
          secretToken: 'Authentifizierungs-Token',
          setupScimCta: 'SCIM einrichten',
        },
      },
      subheader: 'Aktualisieren Sie hier Ihre Organisationsinformationen',
      team: {
        addAnother: 'Weitere hinzufügen',
        addToTeamModal: {
          description:
            'Füge diesen Benutzer als direktes Mitglied einem der verbleibenden Teams hinzu. Teams, denen der Benutzer bereits angehört, werden nicht angezeigt.',
          title: 'Benutzer zum Team hinzufügen',
        },
        headline: 'Team-Management',
        inviteTeamMembersHeader: 'Teammitglieder einladen',
        inviteTeamMembersIntro:
          'Arbeite mit deinen Teammitgliedern an dem Erfolg deiner Organisation.',
        removeFromTeamModal: {
          description:
            'Dieser Benutzer wird aus dem Team entfernt, beachten Sie, dass der Benutzer immer noch seinen Ergebnissen und Aktionen zugeordnet wird.',
          title: 'Sind Sie sicher?',
        },
        sendInvites: 'Einladungen versenden',
        subheader:
          'Verwalte hier deine Teammitglieder und ihre Kontoberechtigungen.',
      },
    },
    personal: {
      displayName: {
        headline: 'Dein Name',
        intro: 'Wie dein Name in der App angezeigt wird',
      },
      externalManaged: {
        description:
          'Kontaktieren Sie Ihren Administrator, um Ihr Profil zu aktualisieren',
        headline: 'Ihr Profil wird von Ihrer Organisation verwaltet',
      },
      headline: 'Konto',
      language: 'Sprache',
      languageDescription: 'Passe an, wie die App für dich angezeigt wird.',
      picture: {
        failedToUpdate: 'Profilbild konnte nicht aktualisiert werden',
        headline: 'Profilbild',
        intro: 'Lade ein Profilbild hoch',
      },
      profile: {
        department: 'Abteilung',
        departmentDescription: 'Die Abteilung, in der du arbeitest',
        displayName: 'Vollständiger Name',
        email: 'E-Mail-Adresse',
        failedToUpdate:
          'Persönliche Informationen konnten nicht aktualisiert werden',
        firstName: 'Vorname',
        headline: 'Persönliche Informationen',
        intro: 'Aktualisiere deine persönlichen Informationen',
        lastName: 'Nachname',
        phoneNumber: 'Telefonnummer',
        updated: 'Persönliche Informationen aktualisiert',
      },
      subheader:
        'Aktualisieren Sie hier Ihre persönlichen Informationen und Einstellungen',
    },
    subheader: 'Passe dein Erlebnis an',
  },
} satisfies Pick<Translation, 'settingsPage'>

export default de_settings
