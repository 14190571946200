import { useAuth } from '@hooks'
import { LoadingOverlay } from '@mantine/core'
import { trpc } from '@utils/trpc'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoginPage from './LoginPage'

type SpecificTenantAuthPageProps = {
  readonly tenantId: string
}

const SpecificTenantAuthPage = ({ tenantId }: SpecificTenantAuthPageProps) => {
  const { user, sessions, switchUser, sessionRestoreFinished } = useAuth()

  const [, setSearchParameters] = useSearchParams()

  const navigate = useNavigate()

  const { data, isPending } =
    trpc['public-router'].getAuthInfoByTenantId.useQuery(tenantId)

  const [checkedExisting, setCheckedExisting] = useState(false)

  const clearQueryStrings = useCallback(() => {
    setSearchParameters((previousParameters) => {
      previousParameters.delete('tenantId')
      previousParameters.delete('tenant')
      previousParameters.delete('org')
      return previousParameters
    })
  }, [setSearchParameters])

  useEffect(() => {
    if (checkedExisting || !sessionRestoreFinished) return

    const processList = [
      ...Object.values(sessions).sort((userA) => {
        return userA.authenticated ? -1 : 1
      }),
    ].filter(Boolean)

    let foundUser = false

    if (user?.teams?.find((team) => team.tenantId === tenantId)) {
      foundUser = true
      setCheckedExisting(true)
      clearQueryStrings()
      return
    }

    for (const userEntry of processList) {
      if (
        !foundUser &&
        userEntry?.latestAccountInfo.teams.find(
          (team) => team.tenantId === tenantId
        )
      ) {
        void switchUser({ navigate, tenantId, username: userEntry.username })
        setCheckedExisting(true)
        foundUser = true

        break
      }
    }

    if (foundUser) {
      setCheckedExisting(true)
      clearQueryStrings()
      return
    }

    setCheckedExisting(true)
  }, [
    checkedExisting,
    clearQueryStrings,
    switchUser,
    tenantId,
    user,
    sessions,
    sessionRestoreFinished,
    navigate,
  ])

  if (isPending || !checkedExisting)
    return (
      <LoadingOverlay
        data-state={JSON.stringify({ checkedExisting, isPending })}
        id='specific-tenant-check.loader'
        visible
      />
    )

  if (data) {
    return <LoginPage forcedLoginInfo={data} onComplete={clearQueryStrings} />
  }

  navigate('/org-not-found')

  return null
}

export default SpecificTenantAuthPage
