import { openAddAccountModal } from '@components/auth'
import UserTenantList from '@components/auth/UserTenantList'
import LoginLayout from '@components/layout/LoginLayout'
import { useAuth } from '@hooks'
import { Button, Menu, Title } from '@mantine/core'
import { useI18nContext } from '@packages/i18n'
import { IconUserPlus } from '@tabler/icons-react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const PickTenantPage = () => {
  const { sessions, user, switchUser } = useAuth()

  const { LL } = useI18nContext()

  const navigate = useNavigate()

  const [parameters, setParameters] = useSearchParams()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let tenantId: string | undefined

    const urlSearchParametersOrgAlias = new URLSearchParams(
      window.location.search
    ).get('org')

    if (urlSearchParametersOrgAlias) {
      const currentTenantId = user?.teams?.find(
        (team) => team.tenant.alias === urlSearchParametersOrgAlias
      )?.tenantId

      if (currentTenantId) {
        tenantId = currentTenantId
      } else {
        const usersThatHaveThatOrg = Object.values(sessions).filter((session) =>
          session.latestAccountInfo?.teams.find(
            (team) => team.tenant.alias === urlSearchParametersOrgAlias
          )
        )
        const authenticatedUsersThatHaveThatOrg = [
          ...usersThatHaveThatOrg,
        ].find(Boolean)

        const newUserName: string | undefined =
          authenticatedUsersThatHaveThatOrg?.username

        tenantId =
          authenticatedUsersThatHaveThatOrg?.latestAccountInfo?.teams.find(
            (team) => team.tenant.alias === urlSearchParametersOrgAlias
          )?.tenantId

        if (newUserName)
          void switchUser({ navigate, tenantId, username: newUserName })
        return
      }
    }

    if (!tenantId)
      tenantId = localStorage.getItem('tenant-id')?.replaceAll('"', '')
    if (tenantId && user?.username)
      void switchUser({
        navigate,
        tenantId,
        username: user.username,
      })
  }, [])

  useEffect(() => {
    if (parameters.get('pagestate') === 'add-account') {
      openAddAccountModal()
      setParameters({})
    }
  }, [parameters, setParameters])

  return (
    <LoginLayout>
      <div className='mt-20'>
        <Title mb='lg' ml='xs' order={5}>
          {LL.auth.selectOrganization()}
        </Title>
        <Menu>
          <UserTenantList />
          <Button
            c='black'
            className='mt-4'
            leftSection={<IconUserPlus size={14} />}
            onClick={() => openAddAccountModal()}
            variant='white'
          >
            {LL.auth.addAccount()}
          </Button>
        </Menu>
      </div>
    </LoginLayout>
  )
}

export default PickTenantPage
