import type { Translation } from '../i18n-types'

const de_emails = {
  emails: {
    checkinNotification: {
      button: 'Jetzt einchecken',
      headline: 'Hey, es ist Zeit zum Einchecken!',
      intro:
        '{organizationName} wartet noch auf Ihren Check-In. Bitte nehmen Sie sich einen Moment Zeit um uns den Status ihrer Items und Ihren Fortschritt mitzuteilen.',
      previewText: 'Es ist Zeit, für {organizationName} einzuchecken',
      status: {
        allMissing: 'Sie haben {missing} Check-Ins offen.',
        completed: 'Sie haben diese Woche bereits eingecheckt.',
        nothingToCheckIn: 'Sie haben diese Woche nichts zum Einchecken.',
        partiallyCompleted:
          'Sie haben noch {missing} von {total} Check-Ins offen.',
      },
      subject: 'Erinnerung zum Einchecken für {organizationName}',
    },
    common: {
      code: 'Bestätigungscode',
      contact: {
        intro: 'Wenn Sie Fragen haben, wenden Sie sich bitte an',
        manageEmailPreferences: 'Verwalten Sie Ihre E-Mail-Einstellungen',
        noMoreMails: 'Möchten Sie keine weiteren E-Mails wie diese erhalten?',
        or: 'oder',
      },
      expirationDisclaimer:
        'Bitte beachten Sie, dass dieser {type} nur für {validity} Stunde{{validity:n}} gültig ist. Wenn Sie Ihre E-Mail-Adresse nicht innerhalb dieses Zeitrahmens bestätigen, müssen Sie einen neuen {type} anfordern.',
      fallbackLinkDisclaimer:
        'Wenn Sie Probleme beim Klicken auf die Schaltfläche haben, kopieren Sie die folgende URL und fügen Sie sie in Ihrem Browser ein:',
      greeting: 'Hallo {0},',
      greetingAnonymous: 'Hallo,',
      link: 'Link',
    },
    confirmationCode: {
      headline: 'Bestätigen Sie Ihre E-Mail-Adresse',
      intro:
        'Wir haben eine Anfrage erhalten, um Ihre E-Mail-Adresse für Ihr GrowthSquare-Konto zu bestätigen. Bitte verwenden Sie den folgenden Bestätigungscode, um Ihre E-Mail-Adresse zu bestätigen:',
      previewText: 'Ihr Bestätigungscode lautet {code}',
      subject: 'Ihr Bestätigungscode',
    },
    forgotPassword: {
      button: 'Passwort zurücksetzen',
      headline: 'Setzen Sie Ihr Passwort zurück',
      intro:
        'Wir haben eine Anfrage erhalten, um Ihr Passwort zurückzusetzen. Wenn Sie diese Anfrage nicht gestellt haben, können Sie diese E-Mail ignorieren. Andernfalls können Sie Ihr Passwort zurücksetzen, indem Sie auf die Schaltfläche unten klicken.',
      previewText: 'Ihr Passwort Reset Link für GrowthSquare',
      subject: 'Ihr Passwort zurücksetzen',
    },
    newInvitation: {
      button: 'Einladung annehmen',
      headline: 'Sie wurden eingeladen!',
      intro:
        'Sie wurden eingeladen, der GrowthSquare-Organisation {organizationName} beizutreten. Klicken Sie auf die Schaltfläche unten, um loszulegen.',
      previewText: 'Sie wurden zu einer GrowthSquare-Organisation eingeladen',
      subject: 'Sie wurden zu einer Organisation eingeladen',
    },
  },
} satisfies Pick<Translation, 'emails'>

export default de_emails
