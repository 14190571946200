import { modals } from '@mantine/modals'
import AddAccountModal, { type AddAccountModalProps } from './AddAccountModal'

const MODAL_ID = 'add-account-modal'

export const openAddAccountModal = (
  props?: AddAccountModalProps,
  clearQueryStrings = true
) => {
  modals.open({
    children: (
      <AddAccountModal
        authflow={props?.authflow}
        loginInfo={props?.loginInfo}
        username={props?.username}
      />
    ),
    closeOnClickOutside: false,
    id: MODAL_ID,
    onClose: () => {
      if (clearQueryStrings) {
        // clear query params
        window.history.replaceState({}, '', window.location.pathname)
      }

      modals.closeAll()
    },
    size: 'md',
    withCloseButton: true,
  })
}
